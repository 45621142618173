<template>
  <div class="antialiased bg-gray-50 dark:bg-gray-900 min-h-screen">
    <TopNav @toggle-sidebar="toggleSidebar"  />
    <SideMenu :isOpen="isSidebarOpen" @close-sidebar="isSidebarOpen = false" />

    <div v-if="showConfirmModal" class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div class="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6 max-w-sm w-full">
        <h2 class="text-xl font-semibold text-gray-800 dark:text-gray-100 mb-4">
          Confirm Removal
        </h2>
        <p class="text-gray-600 dark:text-gray-400 mb-6" v-if="itemType == 'course'">
          Are you sure you want to remove this {{ itemType }} from the group? This will also unassign this course from all users within this group.
        </p>
        <p class="text-gray-600 dark:text-gray-400 mb-6" v-else>
          Are you sure you want to remove this {{ itemType }} from the group? This will also unassign all group courses from the user.
        </p>        
        <div class="flex justify-end space-x-4">
          <button @click="showConfirmModal = false" class="px-4 py-2 bg-gray-500 text-white rounded-lg">
            Cancel
          </button>
          <button @click="confirmDelete" class="px-4 py-2 bg-red-600 text-white rounded-lg">
            Confirm
          </button>
        </div>
      </div>
    </div>

    <!-- Group Title Banner -->
    <main class="pt-20 h-auto bg-gray-200 shadow-md flex justify-center">
      <div class="max-w-screen-lg w-full md:ml-64">
        <div class="mb-4 flex justify-between items-center">
          <a href="/groups" class="mt-4 ml-4 flex items-center text-primary-700 font-bold focus:outline-none">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
            </svg>
            <span class="ml-2">Back</span>
          </a>
        </div>

        <header class="text-gray-800 py-6 px-10 flex justify-between items-center rounded-lg">
          <div>
            <h2 class="text-md font-semibold mb-3">Groups</h2>
            <h1 class="text-4xl font-semibold">{{ group.name }}</h1>
          </div>
          <router-link :to="{ name: 'EditGroup', params: { id: group.id } }"
            class="bg-gray-700 text-white font-semibold hover:bg-gray-800 py-2 px-4 rounded inline-flex items-center mt-4 md:mt-0">
            <svg class="w-5 h-5 mr-2 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24"
              height="24" fill="none" viewBox="0 0 24 24">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M21 13v-2a1 1 0 0 0-1-1h-.757l-.707-1.707.535-.536a1 1 0 0 0 0-1.414l-1.414-1.414a1 1 0 0 0-1.414 0l-.536.535L14 4.757V4a1 1 0 0 0-1-1h-2a1 1 0 0 0-1 1v.757l-1.707.707-.536-.535a1 1 0 0 0-1.414 0L4.929 6.343a1 1 0 0 0 0 1.414l.536.536L4.757 10H4a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h.757l.707 1.707-.535.536a1 1 0 0 0 0 1.414l1.414 1.414a1 1 0 0 0 1.414 0l.536-.535 1.707.707V20a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1v-.757l1.707-.708.536.536a1 1 0 0 0 1.414 0l1.414-1.414a1 1 0 0 0 0-1.414l-.535-.536.707-1.707H20a1 1 0 0 0 1-1Z" />
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M12 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
            </svg>
            Edit Group
          </router-link>
        </header>
        <div class="border-b border-gray-200 dark:border-gray-700">
          <nav class="flex space-x-4 -mb-px">
            <button @click="activeTab = 'users'" :class="tabClass('users')"
              class="py-4 px-6 border-b-2 font-medium text-sm focus:outline-none">
              Users
            </button>
            <button @click="activeTab = 'courses'" :class="tabClass('courses')"
              class="py-4 px-6 border-b-2 font-medium text-sm focus:outline-none">
              Courses
            </button>
          </nav>
        </div>
      </div>
    </main>

    <!-- Main Content Wrapper -->
    <main class="p-6 md:ml-64 h-auto">
      <section class="max-w-screen-lg mx-auto mt-6">
        <div v-if="loading" class="text-center py-10">
          <svg aria-hidden="true" class="w-14 h-14 mx-auto text-gray-200 animate-spin dark:text-gray-600 fill-secondary-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
          </svg>
          <span class="sr-only">Loading...</span>
        </div>

        <!-- Users Tab Content -->
        <div v-if="!loading && activeTab === 'users'" class="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6 mt-4">
          <div class="flex justify-between items-center mb-4">
            <h3 class="text-lg font-semibold text-gray-800 dark:text-gray-100">Users in Group</h3>
            <button @click="openModal('Add Users', 'users')" class="bg-primary-600 hover:bg-primary-700 text-white font-semibold py-2 px-4 rounded-lg">
              + Add Users
            </button>
          </div>
          <div v-if="group.users && group.users.length > 0">
            <table class="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
              <thead class="bg-gray-100 dark:bg-gray-700">
                <tr>
                  <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">Name</th>
                  <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">Email</th>
                  <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">Actions</th>
                </tr>
              </thead>
              <tbody class="bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-700">
                <tr v-for="user in group.users" :key="user.id">
                  <td class="px-6 py-4 whitespace-nowrap text-gray-800 dark:text-gray-100">{{ user.first_name }} {{ user.last_name }}</td>
                  <td class="px-6 py-4 whitespace-nowrap text-gray-500 dark:text-gray-400">{{ user.email }}</td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <button @click="confirmRemoveUser(user.id)" class="text-red-600 hover:underline">Remove</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-else class="text-center">
            <p class="text-gray-600 dark:text-gray-400 mb-4">No users found in this group.</p>
          </div>
        </div>

        <!-- Courses Tab Content -->
        <div v-if="!loading && activeTab === 'courses'" class="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6 mt-4">
          <div class="flex justify-between items-center mb-4">
            <h3 class="text-lg font-semibold text-gray-800 dark:text-gray-100">Courses in Group</h3>
            <button @click="openModal('Add Courses', 'courses')" class="bg-primary-600 hover:bg-primary-700 text-white font-semibold py-2 px-4 rounded-lg">
              + Add Courses
            </button>
          </div>
          <div v-if="group.courses && group.courses.length > 0">
            <table class="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
              <thead class="bg-gray-100 dark:bg-gray-700">
                <tr>
                  <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">Course Title</th>
                  <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">Actions</th>
                </tr>
              </thead>
              <tbody class="bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-700">
                <tr v-for="course in group.courses" :key="course.id">
                  <td class="px-6 py-4 whitespace-nowrap text-gray-800 dark:text-gray-100">{{ course.title }}</td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <button @click="confirmRemoveCourse(course.id)" class="text-red-600 hover:underline">Remove</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-else class="text-center">
            <p class="text-gray-600 dark:text-gray-400 mb-4">No courses assigned to this group.</p>
          </div>
        </div>
      </section>

      <!-- Modal for Adding Users or Courses -->
      <AddUsersCoursesModal
        :show="showModal"
        :title="modalTitle"
        :items-endpoint="modalItemsEndpoint"
        :group-items="modalItemsEndpoint === 'users' ? group.users : group.courses"
        @close="showModal = false"
        @refreshGroupData="fetchGroup"
      />
    </main>
  </div>
</template>

<script>
import TopNav from "@/components/headers/TopNav.vue";
import SideMenu from "@/components/SideMenu.vue";
import AddUsersCoursesModal from "@/components/Admin/AddUsersCoursesModal.vue";
import axios from "axios";

export default {
  components: {
    TopNav,
    SideMenu,
    AddUsersCoursesModal,
  },
  data() {
    return {
      group: { users: [], courses: [] },
      loading: true,
      error: null,
      activeTab: "users",
      showModal: false,
      modalTitle: "",
      modalItems: [],
      allUsers: [],
      allCourses: [],
      modalItemsEndpoint: "",
      showConfirmModal: false,
    itemType: '',  // 'user' or 'course'
    itemToDelete: null,
    isSidebarOpen: false,
    };
  },
  methods: {
    toggleSidebar() {
            this.isSidebarOpen = !this.isSidebarOpen;
        },
    confirmRemoveUser(userId) {
    this.itemType = 'user';
    this.itemToDelete = userId;
    this.showConfirmModal = true;
  },
  confirmRemoveCourse(courseId) {
    this.itemType = 'course';
    this.itemToDelete = courseId;
    this.showConfirmModal = true;
  },
  async confirmDelete() {
    this.showConfirmModal = false;
    if (this.itemType === 'user') {
      await this.removeUser(this.itemToDelete);
    } else if (this.itemType === 'course') {
      await this.removeCourse(this.itemToDelete);
    }
    this.itemToDelete = null; // Reset after deletion
  },
    async fetchGroup() {
      this.loading = true;
      this.error = null;

      try {
        const response = await axios.post(`${process.env.VUE_APP_API_URL}/graphql`, {
          query: `
            query ($id: ID!) {
              group(id: $id) {
                id
                name
                users {
                  id
                  first_name
                  last_name
                  email
                }
                courses {
                  id
                  title
                }
              }
            }
          `,
          variables: { id: this.$route.params.id },
        });
        this.group = response.data.data.group || { users: [], courses: [] };
      } catch (error) {
        console.error("Error fetching group:", error);
        this.error = "Failed to load group data. Please try again.";
      } finally {
        this.loading = false;
      }
    },
    openModal(title, type) {
      this.modalTitle = title;
      this.showModal = true;
      this.modalItemsEndpoint = type === 'users' ? 'users' : 'courses';
    },
    async removeUser(userId) {
      const mutation = `
        mutation($group_id: Int!, $user_id: Int!) {
          removeUserFromGroup(group_id: $group_id, user_id: $user_id) {
            id
            users {
              id
              first_name
              last_name
              email
            }
          }
        }
      `;
      try {
        await axios.post(`${process.env.VUE_APP_API_URL}/graphql`, {
          query: mutation,
          variables: { group_id: parseInt(this.group.id), user_id: parseInt(userId) },
        });
        this.fetchGroup();
      } catch (error) {
        console.error("Error removing user:", error);
      }
    },
    async removeCourse(courseId) {
      const mutation = `
        mutation($group_id: Int!, $course_id: Int!) {
          removeCourseFromGroup(group_id: $group_id, course_id: $course_id) {
            id
            courses {
              id
              title
            }
          }
        }
      `;
      try {
        await axios.post(`${process.env.VUE_APP_API_URL}/graphql`, {
          query: mutation,
          variables: { group_id: parseInt(this.group.id), course_id: parseInt(courseId) },
        });
        this.fetchGroup();
      } catch (error) {
        console.error("Error removing course:", error);
      }
    },
    tabClass(tab) {
      return {
        "border-blue-600 text-blue-600 dark:text-blue-300": this.activeTab === tab,
        "border-transparent text-gray-500": this.activeTab !== tab,
      };
    },
  },
  created() {
    this.fetchGroup();
  },
};
</script>

<style scoped>
.tab-content {
  background-color: white;
  padding: 1.5rem;
  border-radius: 0.5rem;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}
</style>
