<template>
  {{ 'TESTERRRRRRRRRRR' }}
  {{ isOpen }}
  <aside ref="sideMenu"
    @click.stop
    :class="[
      'fixed top-0 left-0 z-40 w-64 h-screen pt-14 transition-transform bg-white border-r border-gray-200 dark:bg-gray-800 dark:border-gray-700',
      isOpen ? 'translate-x-0' : '-translate-x-full'
    ]"
    class="fixed top-0 left-0 z-1 max-w-sm w-full h-screen pt-14 transition-transform -translate-x-full sm:translate-x-0 bg-white border-r border-gray-200 dark:bg-gray-800 dark:border-gray-700"
    aria-label="Sidenav"
    id="drawer-navigation"
  >
    <div class="overflow-y-auto py-5 px-3 pt-5 h-full bg-gray-200 dark:bg-gray-800">
      <div class="mb-5 flex justify-between items-center">
        <button
          @click="goBackToCourse"
          class="mt-4 ml-4 flex items-center text-primary-700 dark:text-primary-300 focus:outline-none"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M10 19l-7-7m0 0l7-7m-7 7h18"
            />
          </svg>
          <span class="ml-2">Back</span>
        </button>
      </div>

      <!-- NewCourse Card: Static and not draggable -->
      <div
        @click="goToNewCourse"
        class="bg-white rounded-md shadow-md p-4 mb-4 flex justify-between items-center"
      >
        <div class="flex items-center">
          <div class="ml-4">
            <h3 class="text-lg font-semibold">{{ courseTitle || '' }}</h3>
            <!-- Progress Bar -->
            <div class="w-full bg-gray-300 rounded-full h-2.5 mt-2">
              <div
                class="bg-blue-600 h-2.5 rounded-full"
                :style="{ width: `${courseProgress}%` }"
              ></div>
            </div>
            <span class="text-sm text-gray-600 dark:text-gray-400">{{ courseProgress.toFixed(0) }}% Complete</span>
          </div>
        </div>
      </div>

      <h3 class="text-lg font-semibold mb-2">Course Content</h3>

      <!-- List of Units -->
      <ul>
        <li
          v-for="unit in allUnits"
          :key="unit.id"
          @click="selectUnit(unit.unit_order)"
          :class="unit.is_complete ? 'border-l-4 border-green-500' : ''"
        >
          <div class="flex justify-between items-center bg-white dark:bg-gray-700 rounded-md shadow-md p-4 mb-4 cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-600">
            <div>
              <span :class="getUnitIconClass(unit.__typename)" class="mr-2"></span>
              {{ unit.title || 'Untitled Unit' }} <!-- Default title for units without a title -->
            </div>
            <!-- Show "Complete" badge if the unit is complete -->
            <span
              v-if="unit.is_complete"
              class="text-xs bg-green-100 text-green-600 px-2 py-1 rounded-full font-semibold"
            >
              Complete
            </span>
          </div>
        </li>
      </ul>
    </div>
  </aside>
</template>

<script>
export default {
  data() {
    return {
      courseProgress: 0,
    };
  },
  computed: {
    allUnits() {
      const { contents, videos, audios, documents, iframes, scorms, tests, assignments } = this.units;

      return [
        ...(contents || []),
        ...(videos || []),
        ...(audios || []),
        ...(documents || []),
        ...(iframes || []),
        ...(scorms || []),
        ...(tests || []).map(test => ({ ...test, title: test.title || 'Test Unit' })),
        ...(assignments || []),
      ].sort((a, b) => a.unit_order - b.unit_order);
    },
  },
  methods: {
    selectUnit(unitOrder) {
      this.$emit('unit-selected', unitOrder);
    },
    goToNewCourse() {
      this.$emit('view-new-course');
    },
    getUnitIconClass(type) {
      switch (type) {
        case 'Content':
          return 'fas fa-file-alt';
        case 'Video':
          return 'fas fa-video';
        case 'Audio':
          return 'fas fa-music';
        case 'Document':
          return 'fas fa-file-pdf';
        case 'iFrame':
          return 'fas fa-globe';
        case 'Test':
          return 'fas fa-check-circle';
        case 'Assignments':
          return 'fas fa-tasks';
        case 'Scorm':
          return 'fas fa-laptop';
        default:
          return 'fas fa-file';
      }
    },
    updateCourseProgress() {
      const totalUnits = this.allUnits.length;
      const completedUnits = this.allUnits.filter(unit => unit.is_complete).length;
      this.courseProgress = totalUnits ? (completedUnits / totalUnits) * 100 : 0;
    },
    goBackToCourse() {
      console.log(this.courseId)
      this.$router.push({ name: 'ViewCourse', params: { id: this.courseId } });
    },
    handleClickOutside(event) {
      if (this.$refs.sideMenu && !this.$refs.sideMenu.contains(event.target)) {
        this.$emit("close-sidebar"); // Notify parent to close sidebar
      }
    }
  },
  props: {
    courseTitle: {
      type: String,
      required: true,
    },
    units: {
      type: Object,
      required: true,
    },
    courseId: {
      type: Number,
      required: true,
    },
    isOpen: {
            type: Boolean,
            default: false
        }
  },
  watch: {
    units: {
      handler() {
        this.updateCourseProgress();
      },
      immediate: true,
      deep: true,
    },
    isOpen(newVal) {
      if (newVal) {
        setTimeout(() => {
          document.addEventListener("click", this.handleClickOutside);
        }, 50); // Small delay to allow Vue to update
      } else {
        document.removeEventListener("click", this.handleClickOutside);
      }
    }
  },
  beforeUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
  },
  created() {
    this.updateCourseProgress(); // Ensure progress is calculated on load
  },
};
</script>
