<template>
    <BasicHeader :branch="branch" />
    <section class="bg-gray-50 relative z-0">
      <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <h1
          class="mb-7 text-3xl font-normal leading-none tracking-tight text-gray-900 md:text-4xl lg:text-5xl dark:text-white">
          Reset your password
        </h1>
  
        <div
          class="w-full bg-white rounded-md shadow-xl dark:border md:mt-0 sm:max-w-xl xl:p-0 dark:bg-gray-800 dark:border-gray-700">
          <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
            <form @submit.prevent="sendResetEmail" class="space-y-4 md:space-y-6">
              <div>
                <label for="email" class="block mb-2 text-lg font-medium capitalize text-gray-900 dark:text-white">
                  Your email
                </label>
                <input v-model="email" type="email" name="email" id="email"
                  class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-sm focus:ring-primary-600 focus:border-primary-600 block w-full px-10 py-4 placeholder-gray-400"
                  placeholder="Email address" required>
              </div>
              <button
                type="submit"
                :disabled="loading"
                class="w-full min-h-[2.5rem] text-white !bg-primary-700 hover:!bg-blue-900 focus:ring-4 focus:outline-none focus:ring-primary-300 font-semibold rounded-md text-sm px-5 py-2.5 text-center flex items-center justify-center relative"
              >
                <span v-if="loading" class="absolute flex justify-center items-center uppercase">
                  <svg class="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none"
                    viewBox="0 0 24 24">
                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                    <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
                  </svg>
                </span>
                <span v-else>
                  SEND RESET LINK
                </span>
              </button>
              <p v-if="error" class="text-sm font-bold text-red-500">{{ error }}</p>
              <p v-if="success" class="text-sm font-bold text-green-500">{{ success }}</p>
              <p class="text-sm font-light text-gray-500 dark:text-gray-400">
                Remembered your password? <router-link to="/login"
                  class="font-medium text-primary-600 hover:underline dark:text-primary-500">Log in</router-link>
              </p>
            </form>
          </div>
        </div>
      </div>
    </section>
  </template>
  
  <script>
  import axios from 'axios';
  import BasicHeader from './headers/BasicHeader.vue';
  import { mapActions, mapGetters } from 'vuex';
  
  export default {
    data() {
      return {
        email: '',
        error: null,
        success: null,
        loading: false,
      };
    },
    components: {
      BasicHeader,
    },
    computed: {
      ...mapGetters(['getBranch']),
      branch() {
        return this.getBranch;
      },
    },
    methods: {
      ...mapActions(['fetchBranch']),
      async loadBranchData() {
        const host = window.location.host;
        const parts = host.split('.');
        const subdomain =
          (parts.length > 2 && host !== 'localhost') || (parts.length > 2 && host !== 'jamlms') ? parts[0] : null;
        if (subdomain) {
          await this.fetchBranch(subdomain);
        }
      },
      async sendResetEmail() {
        this.loading = true; // Show spinner and disable button
        this.error = null;
        this.success = null;

        const branchUrl = this.$store.state.branch ? this.$store.state.branch.url : null;
  
        try {
          const response = await axios.post(`${process.env.VUE_APP_API_URL}/graphql`, {
            query: `
            mutation {
            sendPasswordReset(email: "${this.email}"${branchUrl ? `, branch_url: "${branchUrl}"` : ''})
            }
            `,
          });
  
          if (response.data.errors) {
            const error = response.data.errors[0];
            this.error = error.message || 'An error occurred. Please try again.';
            return;
          }
  
          this.success = 'Password reset email sent successfully. Please check your inbox.';
        } catch (error) {
          console.error('Reset email error:', error);
          this.error = 'An error occurred while sending the reset email. Please try again.';
        } finally {
          this.loading = false;
        }
      },
    },
    created() {
      this.loadBranchData();
    },
  };
  </script>
  