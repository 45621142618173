<template>
    <div class="bg-primary-700 min-h-screen relative contact-page">
      <TopNav @toggle-sidebar="toggleSidebar"  />
  
      <header class="contact-page relative">
        <div class="gradient-cross absolute inset-0 -z-10"></div>
        <div class="white-cross absolute inset-0 -z-20"></div>
        <div class="contact-content relative z-10">
          <h1 class="text-6xl text-secondary-600">Contact the Jam LMS team</h1>
          <p class="text-2xl">Our team is happy to answer your questions. Fill out the form and we'll be in touch as soon as possible.</p>
        </div>
      </header>
  
      <div class="mx-auto mt-16 relative z-10 max-w-5xl">
            <div class="grid grid-cols-1 md:grid-cols-4 gap-6 ">
              <!-- Card 1: Live Chat -->
              <div class="bg-white shadow-sm border border-gray-300 rounded-lg p-6 py-4 flex flex-col items-start">
                <div class="border border-gray-300 p-1 rounded-md mb-8">
                  <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 10.5h.01m-4.01 0h.01M8 10.5h.01M5 5h14a1 1 0 0 1 1 1v9a1 1 0 0 1-1 1h-6.6a1 1 0 0 0-.69.275l-2.866 2.723A.5.5 0 0 1 8 18.635V17a1 1 0 0 0-1-1H5a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1Z"/>
                  </svg>
                </div>
                <h3 class="text-md font-semibold mb-4 text-primary-700">Live Chat with Us</h3>
                <p class="text-sm text-gray-600 mb-4">Chat with our team for instant support and help.</p>
                <a href="#" class="text-gray-700 text-sm font-medium">Start a Live Chat</a>
              </div>

              <!-- Card 2: Call Us -->
              <div class="bg-white shadow-sm border border-gray-300 rounded-lg p-6 py-4 flex flex-col items-start">
                <div class="border border-gray-300 p-1 rounded-md mb-8">
                  <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M18.427 14.768 17.2 13.542a1.733 1.733 0 0 0-2.45 0l-.613.613a1.732 1.732 0 0 1-2.45 0l-1.838-1.84a1.735 1.735 0 0 1 0-2.452l.612-.613a1.735 1.735 0 0 0 0-2.452L9.237 5.572a1.6 1.6 0 0 0-2.45 0c-3.223 3.2-1.702 6.896 1.519 10.117 3.22 3.221 6.914 4.745 10.12 1.535a1.601 1.601 0 0 0 0-2.456Z"/>
                  </svg>
                  
                </div>
                <h3 class="text-md font-semibold mb-4 text-primary-700">Call Us</h3>
                <p class="text-sm text-gray-600 mb-4">Get in touch with our support team via phone.</p>
                <a href="tel:0800 000 0000" class="text-gray-700 text-sm font-medium">0800 000 000</a>
              </div>

              <!-- Card 3: Facebook Messenger -->
              <div class="bg-white shadow-sm border border-gray-300 rounded-lg p-6 py-4 flex flex-col items-start">
                <div class="border border-gray-300 p-1 rounded-md mb-8">
                  <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                    <path fill-rule="evenodd" d="M13.135 6H15V3h-1.865a4.147 4.147 0 0 0-4.142 4.142V9H7v3h2v9.938h3V12h2.021l.592-3H12V6.591A.6.6 0 0 1 12.592 6h.543Z" clip-rule="evenodd"/>
                  </svg>
                  
                  
                </div>
                <h3 class="text-md font-semibold mb-4 text-primary-700">Facebook Messenger</h3>
                <p class="text-sm text-gray-600 mb-4">Message us on Facebook for assistance.</p>
                <a href="#" class="text-gray-700 text-sm font-medium">Open Messenger</a>
              </div>

              <!-- Card 4: Send Us an Email -->
              <div class="bg-white shadow-sm border border-gray-300 rounded-lg p-6 py-4 flex flex-col items-start">
                <div class="border border-gray-300 p-1 rounded-md mb-8">
                    <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                        <path stroke="currentColor" stroke-linecap="round" stroke-width="2" d="m3.5 5.5 7.893 6.036a1 1 0 0 0 1.214 0L20.5 5.5M4 19h16a1 1 0 0 0 1-1V6a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1Z"/>
                      </svg>
                      
                </div>
                <h3 class="text-md font-semibold mb-4 text-primary-700">Send Us an Email</h3>
                <p class="text-sm text-gray-600 mb-4">Email our team and we will respond promptly.</p>
                <a href="mailto:support@jamlms.com" class="text-gray-700 text-sm font-medium">support@jamlms.com</a>
              </div>
            </div>
        <div class="grid grid-cols-1 md:grid-cols-3 gap-8 mt-10">
            
          <div class="md:col-span-2">
            <form @submit.prevent="handleSubmit" class="contact-form">
                <h1 class="text-2xl mb-5 text-secondary-700 font-medium">Message us online</h1>

              <div v-if="successMessage" class="alert alert-success">
                {{ successMessage }}
              </div>
  
              <div class="form-row">
                <label for="firstname">First Name</label>
                <input 
                  id="firstname" 
                  v-model="form.firstname" 
                  type="text" 
                  required
                  placeholder="John"
                />
              </div>
  
              <div class="form-row">
                <label for="lastname">Last Name</label>
                <input 
                  id="lastname" 
                  v-model="form.lastname" 
                  type="text" 
                  required
                  placeholder="Smith"
                />
              </div>
  
              <div class="form-row">
                <label for="email">Email</label>
                <input 
                  id="email" 
                  v-model="form.email" 
                  type="email" 
                  required
                  placeholder="john.smith@example.com"
                />
              </div>
  
              <div class="form-row">
                <label for="country">Country</label>
                <select 
                  id="country" 
                  v-model="form.country" 
                  required
                >
                  <option value="" disabled>Choose country...</option>
                  <option v-for="country in countries" :key="country.code" :value="country.code">
                    {{ country.name }}
                  </option>
                </select>
              </div>
  
              <div class="form-row">
                <label for="message">Your Question</label>
                <textarea 
                  id="message" 
                  v-model="form.message" 
                  required
                  placeholder="Tell us how we can help?"
                ></textarea>
              </div>
  
              <div class="submit-row">
                <button type="submit" class="submit-button common-Button common-Button--default">Contact Sales</button>
              </div>
            </form>
          </div>
  
          <div class="contact-right !text-black">
            <ul>
              <li class="mb-6">
                <i class="fas fa-map-marker-alttext-3xl mb-2"></i>
                <p class="text-sm">Credit Guru,<br>302 Chambers Business Centre,<br>Chapel Road, Hollinwood,<br>Oldham, OL8 4QQ</p>
              </li>
              <li class="mb-6">
                <i class="fas fa-headset text-3xl mb-2"></i>
                <p class=" text-sm">Live chat</p>
              </li>
              <li>
                <i class="fas fa-envelop text-3xl mb-2"></i>
                <p class="text-sm">hello@credit-guru.co.uk</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import TopNav from '@/components/headers/TopNav.vue';
  
  export default {
    data() {
      return {
        form: {
          firstname: '',
          lastname: '',
          email: '',
          country: '',
          message: ''
        },
        successMessage: '',
        countries: [
          { code: 'AF', name: 'Afghanistan' },
          { code: 'AL', name: 'Albania' },
          { code: 'DZ', name: 'Algeria' },
          { code: 'US', name: 'United States' },
          { code: 'GB', name: 'United Kingdom' },
        ]
      };
    },
    components: {
      TopNav,
    },
    methods: {
      handleSubmit() {
        this.successMessage = 'Your message has been sent successfully!';
        this.form = {
          firstname: '',
          lastname: '',
          email: '',
          country: '',
          message: ''
        };
      }
    }
  };
  </script>
  
  <style scoped>
  .contact-page {
    font-family: "Montserrat", sans-serif;
  }
  .contact-content {
    font-family: "Montserrat", sans-serif;
    margin: 0 auto;
    text-align: center;
    padding: 200px 0 0;
  }
  
  .contact-content h1 {
    letter-spacing: -0.01em;
    line-height: 68px;
    margin: 0 0 20px;
    font-weight: 500;
  }
  
  .contact-content p {
    line-height: 36px;
    font-weight: 300;
    color: #2f4158;
  }
  
  header.contact-page {
    padding: 0;
    margin: 0;
    position: relative;
    z-index: 0;
  }
  
  .gradient-cross {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -10;
  }
  
  .white-cross {
    pointer-events: none;
    position: absolute;
    left: 0;
    right: 0;
    top: -180px;
    height: 73vh;
    background: linear-gradient(270deg, #f6f9fc, #fff);
    transform: skewY(-10deg);
    z-index: -20;
  }
  
  .contact-right {
    margin-top: 120px;
    text-align: center;
    color: #fff;
  }
  
  .contact-right .fas {
    font-size: 35px;
    margin-bottom: 20px;
  }
  
  .contact-right p {
    font-size: 17px;
    line-height: 25px;
  }
  
  .contact-form {
    border-radius: 4px;
    box-shadow: 0 50px 100px -20px rgba(50, 50, 93, 0.25), 0 30px 60px -30px rgba(0, 0, 0, 0.3), 0 -18px 60px -10px rgba(0, 0, 0, 0.025);
    max-width: 645px;
    background: #fff;
    margin: 0 auto;
    padding: 35px 18px 30px 24px;
    border-right: 1px solid #dedede;
    border-left: 1px solid #dedede;
    margin: 30px 0 90px;
  }
  
  .form-row {
    padding: 8px 13px 2px 17px;
    border-bottom: 1px solid #e6eef8;
    display: flex;
    padding: 4px 0;
    max-width: 600px;
  }
  
  .contact-form label {
    color: #2c2c2c;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.43px;
    line-height: 26px;
    flex: 17%;
    text-transform: uppercase;
  }
  
  .contact-form input,
  .contact-form select {
    background-color: #e4e4e4;
    padding: 7px 20px;
    flex: 45%;
    border-radius: 4px;
    color: #32325d;
    font-size: 15px;
    margin:10px 0;
  }
  
  .contact-form textarea {
    background-color: #e4e4e4;
    padding: 5px 20px 8px 13px;
    flex: 45%;
    border-radius: 4px;
    color: #32325d;
    font-size: 17px;
    height: 128px;
  }
  
  input.submit-button.common-Button.common-Button--default {
    white-space: nowrap;
    height: 40px;
    line-height: 40px;
    padding: 0 14px;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    background: #073776;
    border-radius: 4px;
    font-size: 15px;
    font-weight: 600;
    text-transform: uppercase;
    color: #ffffff;
  }
  
  .submit-row {
    text-align: right;
    display: flex;
    justify-content: flex-end;
    padding: 12px 10px 13px 0;
  }
  
  .footer-top {
    padding: 65px 0;
  }
  
  .footer-nav-wrap h4 {
    font-size: 13px;
    margin-bottom: 1.4rem;
    color: #ffffff;
  }
  
  .footer-nav-wrap ul li a {
    font-size: 13px;
    line-height: 24px;
    padding: 3px 0;
    color: #ffffff;
    opacity: 0.8;
  }
  
  .footer-bottom {
    padding: 12px 0;
  }
  
  .payment-method ul {
    display: inline-flex;
    align-items: center;
    margin-bottom: 0;
  }
  
  .payment-method ul li img {
    max-width: 60px;
  }
  
  </style>
  