<template>
    <div
      class="relative flex flex-col min-w-0 mb-4 lg:mb-0 mt-4 break-words bg-white border border-gray-200 border-solid dark:bg-gray-800 w-full shadow-lg rounded"
    >
      <div class="rounded-t mb-0 px-0 border-0">
        <CardHeader :title="title"></CardHeader>
  
        <div class="flex flex-wrap items-center px-4 pb-2">
          <div class="w-full pt-1">
            <p class="text-sm text-gray-500">
              Your training branch URL is where you send users to complete their training.
            </p>
            <p class="text-sm text-gray-500 mt-2">Your is:</p>
            <ul class="my-1">
              <li class="flex px-4 my-4">
                <svg
                 @click="copyTrainingURL"
                  class="w-5 h-5 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="currentColor"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 8v3c0 .6-.4 1-1 1H5m11 4h2c.6 0 1-.4 1-1V5c0-.6-.4-1-1-1h-7a1 1 0 0 0-1 1v1m4 3v10c0 .6-.4 1-1 1H6a1 1 0 0 1-1-1v-7.1c0-.3 0-.5.2-.7l2.5-2.9c.2-.2.5-.3.8-.3H13c.6 0 1 .4 1 1Z"
                  />
                </svg>
                <div class="flex-grow flex items-center border-gray-100 text-sm text-gray-600 dark:text-gray-50 ml-1">
                  <div class="flex-grow flex justify-between items-center leading-5">
                    <div class="self-center">
                      <a @click="copyTrainingURL" href="#" class="text-blue-700">{{ trainingURL }}</a>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    </template>
<script>
import CardHeader from '@/partials/CardHeader.vue';

export default {
  components: {
    CardHeader,
  },
  props: {
    title: String,
    trainingURL: String,
  },
  methods: {
    copyTrainingURL() {
        navigator.clipboard.writeText(this.trainingURL)
          .then(() => {
          })
          .catch(err => {
            console.error('Failed to copy: ', err);
          });
      },
    },
};
</script>