<template>
  <div class="antialiased bg-gray-50 dark:bg-gray-900 min-h-screen">
    <TopNav @toggle-sidebar="toggleSidebar"  />
    <SideMenu :isOpen="isSidebarOpen" @close-sidebar="isSidebarOpen = false" />

    <main class="p-6 md:ml-64 h-auto pt-20 flex justify-center">
      <section class="w-full max-w-lg dark:bg-gray-800 rounded-lg p-8">
        <div class="mb-6 flex justify-between items-center">
          <h2 class="text-3xl font-semibold text-gray-800 dark:text-gray-100">Edit Group</h2>
          <router-link :to="`/groups/${group.id}`" class="text-primary-700 dark:text-primary-300">
            Cancel
          </router-link>
        </div>

        <!-- Form for editing group -->
        <form @submit.prevent="updateGroup">
          <div v-if="error" class="mb-4 bg-red-100 text-red-800 p-3 rounded">{{ error }}</div>

          <!-- Group Name Field -->
          <div class="mb-4">
            <label for="name" class="block text-sm font-semibold text-gray-700 dark:text-gray-400 mb-1">
              Group Name
            </label>
            <input type="text" id="name" v-model="group.name"
              class="w-full p-3 border rounded-lg text-gray-800 dark:text-gray-100 dark:bg-gray-700 border-gray-300 dark:border-gray-600 focus:ring-2 focus:ring-primary-500"
              required />
          </div>

          <!-- Group Description Field -->
          <div class="mb-4">
            <label for="description" class="block text-sm font-semibold text-gray-700 dark:text-gray-400 mb-1">
              Description
            </label>
            <textarea id="description" v-model="group.description"
              class="w-full p-3 border rounded-lg text-gray-800 dark:text-gray-100 dark:bg-gray-700 border-gray-300 dark:border-gray-600 focus:ring-2 focus:ring-primary-500"
              rows="3"></textarea>
          </div>

          <!-- Save Button with Loading Spinner -->
          <div class="mt-6">
            <button type="submit" :disabled="isSubmitting"
              class="w-full bg-blue-600 text-white font-semibold py-3 rounded-lg hover:bg-blue-700 transition duration-150 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-opacity-50 flex items-center justify-center">
              <svg v-if="isSubmitting" class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
              </svg>
              {{ isSubmitting ? 'Saving...' : 'Save Changes' }}
            </button>
          </div>
        </form>
      </section>
    </main>
  </div>
</template>

<script>
import axios from 'axios';
import TopNav from '@/components/headers/TopNav.vue';
import SideMenu from '@/components/SideMenu.vue';

export default {
  components: { TopNav, SideMenu },
  data() {
    return {
      group: { name: '', description: '' },
      isSubmitting: false,
      error: null,
      isSidebarOpen: false,
    };
  },
  async created() {
    await this.fetchGroup();
  },
  methods: {
    toggleSidebar() {
            this.isSidebarOpen = !this.isSidebarOpen;
        },
    async fetchGroup() {
      try {
        const response = await axios.post(`${process.env.VUE_APP_API_URL}/graphql`, {
          query: `
              query ($id: ID!) {
                group(id: $id) {
                  id
                  name
                  description
                }
              }
            `,
          variables: { id: this.$route.params.id },
        });
        this.group = response.data.data.group;
      } catch (error) {
        console.error('Error fetching group:', error);
      }
    },
    async updateGroup() {
      this.isSubmitting = true;
      this.error = null;

      try {
        const response = await axios.post(`${process.env.VUE_APP_API_URL}/graphql`, {
          query: `
    mutation ($id: Int!, $input: GroupInput!) {
      updateGroup(id: $id, input: $input) {
        id
        name
        description
      }
    }
  `,
          variables: { id: parseInt(this.group.id), input: { name: this.group.name, description: this.group.description } },
        });


        if (response.data.errors) {
          this.error = response.data.errors[0].message;
        } else {
          this.$router.push({ name: 'ViewGroup', params: { id: this.group.id } });
        }
      } catch (error) {
        console.error('Error updating group:', error);
        this.error = 'An error occurred while updating the group.';
      } finally {
        this.isSubmitting = false;
      }
    },
  },
};
</script>