<template>
  <div class="antialiased bg-gray-50 dark:bg-gray-900">
    <TopNav @toggle-sidebar="toggleSidebar"  />
    <SideMenu :isOpen="isSidebarOpen" @close-sidebar="isSidebarOpen = false" />

    <main class="p-4 md:ml-64 h-auto pt-20">
      <section class="bg-gray-50 dark:bg-gray-900 p-3 sm:p-5">
        <div class="mx-auto max-w-screen-xl px-4 lg:px-12">
          <div class="dark:bg-gray-800 relative sm:rounded-lg overflow-hidden">
            
            <!-- Header & Back Button -->
            <div class="flex flex-col md:flex-row items-center justify-between p-6 text-center md:text-left">
  <button 
    v-if="currentCategory" 
    @click="goBackToParentCategory" 
    class="flex items-center text-gray-700 dark:text-gray-300 hover:text-primary-600 transition mb-2 md:mb-0"
  >
    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
    </svg>
    Back to {{ currentCategory.parent ? currentCategory.parent.name : "All Categories" }}
  </button>

  <h2 class="text-xl sm:text-2xl font-semibold text-gray-900 dark:text-gray-200">
    {{ currentCategory ? currentCategory.name : "Course Catalog" }}
  </h2>
</div>


            <!-- Loading Spinner -->
            <div v-if="loading" class="text-center py-10">
                <svg aria-hidden="true" class="w-14 h-14 mx-auto text-gray-200 animate-spin dark:text-gray-600 fill-secondary-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                  <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                </svg>
                <span class="sr-only">Loading...</span>
              </div>

            <!-- No Content Message -->
            <div v-if="!loading && currentCourses.length === 0 && currentCategories.length === 0" class="text-center text-gray-600 dark:text-gray-400 flex flex-col items-center justify-center h-80">
              <h2 class="text-3xl font-semibold mb-4">No Courses Available</h2>
              <p class="text-lg mb-2">There are no courses or categories available in this section.</p>
            </div>

            <!-- Categories Section (always full-width row) -->
            <div v-if="currentCategories.length" class="p-6">
  <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
    <div 
      v-for="category in currentCategories" 
      :key="category.id" 
      @click="enterCategory(category)"
      class="relative cursor-pointer bg-secondary-700 dark:bg-gray-800 border border-gray-700 shadow-xl hover:shadow-2xl transition-all transform hover:scale-[1.05] p-8 flex flex-col items-center text-center rounded-2xl overflow-hidden group h-56 md:h-64"
    >
      <!-- Background Image -->
      <div 
        v-if="category.image"
        class="absolute inset-0 bg-cover bg-center bg-no-repeat transition-all"
        :style="{ backgroundImage: `url(${apiBaseUrl}${category.image})` }"
      ></div>

      <!-- Dark Overlay for Readability -->
      <div class="absolute inset-0 bg-black bg-opacity-40 group-hover:bg-opacity-50 transition-all"></div>

      <!-- Glowing Edge Effect -->
      <div class="absolute inset-0 border-2 border-transparent rounded-2xl group-hover:border-primary-500 transition-all"></div>

      <!-- Modern Icon Redesign -->
      <div class="relative flex items-center justify-center w-16 h-16 mb-6 rounded-xl bg-secondary-600/40 dark:bg-gray-700 group-hover:bg-primary-500 transition-all z-10 backdrop-blur-md">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 text-gray-300 group-hover:text-white transition" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 11H4m15.5 5a.5.5 0 0 0 .5-.5V8a1 1 0 0 0-1-1h-3.75a1 1 0 0 1-.829-.44l-1.436-2.12a1 1 0 0 0-.828-.44H8a1 1 0 0 0-1 1M4 9v10a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1v-7a1 1 0 0 0-1-1h-3.75a1 1 0 0 1-.829-.44L9.985 8.44A1 1 0 0 0 9.157 8H5a1 1 0 0 0-1 1Z"/>
        </svg>
      </div>

      <!-- Category Name -->
      <h3 class="relative text-xl font-bold text-white group-hover:text-primary-400 transition z-10 shadow-lg backdrop-blur-md">
        {{ category.name }} 
      </h3>

      <!-- Subtext -->
      <span class="relative text-gray-100 dark:text-gray-400 mt-2 text-sm opacity-80 group-hover:opacity-100 transition z-10 shadow-lg backdrop-blur-md">
        Browse Courses →
      </span>
    </div>
  </div>
</div>


            <!-- Courses Section (always below categories) -->
            <div v-if="currentCourses.length" class="p-6">
              <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
                <div v-for="course in currentCourses" :key="course.id" class="bg-white dark:bg-gray-800 rounded-lg shadow-md hover:shadow-lg transition-transform transform hover:scale-105 overflow-hidden border dark:border-gray-700">
                  <img :src="getFullThumbnailUrl(course.thumbnail)" alt="Course Thumbnail" class="w-full h-48 object-cover" />
                  <div class="p-6">
                    <h3 class="text-lg font-semibold text-gray-900 dark:text-gray-200">{{ course?.title || 'Untitled Course' }}</h3>
                    <p class="text-sm text-gray-500 dark:text-gray-400 mt-1">{{ course.price ? `$${course.price}` : 'Free' }}</p>
                  </div>
                  <div class="p-6">
                    <router-link :to="{ name: 'ViewCourse', params: { id: course.id } }" class="w-full bg-primary-600 text-white font-semibold py-2 px-4 rounded-lg hover:bg-primary-700 transition-colors duration-200 text-center block">
                      View Course
                    </router-link>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>
    </main>
  </div>
</template>

  <script>
  import axios from 'axios';
  import TopNav from '@/components/headers/TopNav.vue';
  import SideMenu from '@/components/LearnerSideMenu.vue';
  
  export default {
    components: {
      TopNav,
      SideMenu,
    },
    data() {
      return {
        courses: [],
        categories: [],
        selectedCategories: [],
        searchQuery: '',
        sortBy: 'name',
        loading: true, // Added loading state
        currentCategory: null,
        apiBaseUrl: process.env.VUE_APP_API_URL,
        isSidebarOpen: false,
      };
    },
    computed: {
      currentCategories() {
      return this.categories.filter(category => 
        category.parent_id === (this.currentCategory ? this.currentCategory.id : null) &&
        this.hasCoursesInCategory(category)
      );
    },
    currentCourses() {
      if (!this.currentCategory) {
        return this.courses.filter(course => course.categories.length === 0);
      }
      return this.courses.filter(course => course.categories.some(cat => cat.id === this.currentCategory.id));
    },
    },
    mounted() {
      this.fetchCourses();
      this.fetchCategories();
    },
    methods: {
      toggleSidebar() {
            this.isSidebarOpen = !this.isSidebarOpen;
        },
      getFullThumbnailUrl(thumbnail) {
        if (thumbnail && thumbnail.startsWith('http')) {
          return thumbnail;
        }
        const baseUrl = thumbnail ? process.env.VUE_APP_API_URL : `${process.env.VUE_APP_API_URL}/img`;
        const path = thumbnail ? thumbnail : '/default-course-image.jpg';
        return `${baseUrl}${path}`;
      },
      enterCategory(category) {
      this.currentCategory = category;
    },
    goBackToParentCategory() {
      if (this.currentCategory && this.currentCategory.parent_id) {
        this.currentCategory = this.categories.find(cat => cat.id === this.currentCategory.parent_id);
      } else {
        this.currentCategory = null;
      }
    },
    hasCoursesInCategory(category) {
      return this.courses.some(course => 
        course.categories.some(cat => cat.id === category.id) || 
        this.categories.some(child => child.parent_id === category.id && this.hasCoursesInCategory(child))
      );
    },
      async fetchCourses() {
        try {
          const branchUrl = this.$store.state.branch ? this.$store.state.branch.url : null;

    const response = await axios.post(`${process.env.VUE_APP_API_URL}/graphql`, {
      query: `
        query fetchCourses($branchUrl: String) {
          courses(branchUrl: $branchUrl) {
            id
            title
            thumbnail
            price
            categories {
              id
              name
              image
              parent_id
            }
            date
          }
        }
      `,
      variables: {
        branchUrl, // Pass branchUrl as a variable
      },
    });
  
          this.courses = response.data.data.courses || [];
        } catch (error) {
          console.error('Error fetching courses:', error);
        } finally {
          this.loading = false; // Set loading to false after data is fetched
        }
      },
      async fetchCategories() {
        try {
          const branchUrl = this.$store.state.branch ? this.$store.state.branch.url : null;

const response = await axios.post(`${process.env.VUE_APP_API_URL}/graphql`, {
  query: `
    query fetchCategories($branchUrl: String) {
      categories(branchUrl: $branchUrl) {
        id
        name
        parent_id
        image
      }
    }
  `,
  variables: {
    branchUrl,
  },
});
this.categories = response.data.data.categories || [];
} catch (error) {
          console.error('Error fetching categories:', error);
        }
      },
    },
  };
  </script>
  