<template>
  <CardHeader :title="title" font="lg" class="mb-7"></CardHeader>

  <div class="grid grid-cols-1 md:grid-cols-3 gap-4 mb-7">
    <!-- Donut Chart -->
    <div class="col-span-2">
      <div id="donut-chart" v-if="chartData && chartData.length > 0 && chartData.length === chartLabels.length"></div>
      <p v-else class="text-center text-gray-600 dark:text-gray-400">
        No data available for the chart.
      </p>
    </div>

    <!-- Statistics Summary - Moves below chart on small screens -->
    <div class="order-first md:order-none">
      <dl class="max-w-md text-gray-900 dark:text-gray-50 text-center md:text-left">
        <div class="flex flex-col pb-3">
          <dt class="text-gray-500 md:text-lg dark:text-gray-400 border-b pb-2 mb-2 font-semibold">
            Active Users ({{ activeUsers + inactiveUsers }} total)
          </dt>
          <dd class="text-3xl font-semibold text-gray-800 dark:text-blue-400">
            {{ activeUsers > 0 ? activeUsers + " active" : "0" }}
          </dd>
        </div>

        <div class="flex flex-col py-3">
          <dt class="text-gray-500 md:text-lg dark:text-gray-400 border-b pb-2 mb-2 font-semibold">
            Training Time Logged
          </dt>
          <dd class="text-3xl font-semibold text-gray-800 dark:text-emerald-400">
            {{ trainingTime !== "" ? trainingTime : "0" }}
          </dd>
        </div>

        <div class="flex flex-col pt-3">
          <dt class="text-gray-500 md:text-lg dark:text-gray-400 border-b pb-2 mb-2 font-semibold">
            Completed Courses
          </dt>
          <dd class="text-3xl font-semibold text-gray-800 dark:text-purple-400">
            {{ completedCourses > 0 ? completedCourses : "0" }}
          </dd>
        </div>
      </dl>
    </div>
  </div>
</template>


<script>
import CardHeader from "@/partials/CardHeader.vue";
import ApexCharts from "apexcharts";

export default {
  data() {
    return {
      chart: null,
    };
  },
  components: {
    CardHeader,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    chartData: {
      type: Array,
      required: true,
      default: () => [],
    },
    chartLabels: {
      type: Array,
      required: true,
      default: () => [],
    },
    activeUsers: {
      type: Number,
      default: 0,
    },
    inactiveUsers: {
      type: Number,
      default: 0,
    },
    trainingTime: {
      type: String,
      default: "0",
    },
    completedCourses: {
      type: Number,
      default: 0,
    },
    totalCourses: {
      type: Number,
      default: 0,
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.chartData.length > 0 && this.chartLabels.length > 0) {
        this.renderChart();
      }
    });
  },
  watch: {
    chartData: {
      immediate: true,
      handler(newData) {
        this.$nextTick(() => {
          if (newData.length > 0 && newData.length === this.chartLabels.length) {
            this.renderChart();
          }
        });
      },
    },
    chartLabels: {
      immediate: true,
      handler(newLabels) {
        this.$nextTick(() => {
          if (this.chartData.length > 0 && this.chartData.length === newLabels.length) {
            this.renderChart();
          }
        });
      },
    },
  },
  methods: {
    getChartOptions() {
      return {
        series: this.chartData,
        colors: [
          "#6366F1",
          "#EF4444",
          "#10B981",
          "#F59E0B",
          "#3B82F6",
        ],
        chart: {
          type: "donut",
          height: 400,
        },
        stroke: {
          width: 0,
        },
        plotOptions: {
          pie: {
            donut: {
              size: "70%",
              labels: {
                show: true,
                name: {
                  show: true,
                  fontSize: "16px",
                  color: "#6B7280",
                },
                value: {
                  show: true,
                  fontSize: "20px",
                  color: "#111827",
                  formatter: (val) => `${val} items`,
                },
                total: {
                  show: true,
                  label: "Total",
                  fontSize: "18px",
                  color: "#1F2937",
                  formatter: () => {
                    return this.totalCourses;
                  },
                },
              },
            },
          },
        },
        labels: this.chartLabels,
        legend: {
          position: "right",
          fontSize: "14px",
          labels: {
            colors: ["#4B5563"],
          },
          markers: {
            radius: 8,
          },
        },
        tooltip: {
          theme: "dark",
          y: {
            formatter: (value) => `${value} items`,
          },
        },
        responsive: [
          {
            breakpoint: 768,
            options: {
              chart: {
                width: "100%",
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      };
    },

    renderChart() {
      const chartContainer = document.getElementById("donut-chart");
      if (chartContainer && typeof ApexCharts !== "undefined") {
        if (this.chart) {
          this.chart.destroy();
        }

        this.chart = new ApexCharts(chartContainer, this.getChartOptions());
        this.chart.render();
      }
    },
  },
};
</script>
