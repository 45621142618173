<template>
  <div class="antialiased bg-gray-50 min-h-screen text-gray-800">
    <!-- Top Navigation -->
    <TopNav @toggle-sidebar="toggleSidebar"  />

    <!-- Blue Background Area -->
    <div class="bg-light-blue">
      <div class="container mx-auto p-8 pt-20">
        <!-- Back Button -->
        <button @click="goBack"
          class="mt-4 ml-4 flex items-center text-primary-700 dark:text-primary-300 focus:outline-none">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
          </svg>
          <span class="ml-2">Back</span>
        </button>

        <!-- Course Header Section -->
        <section class="p-6 rounded-lg flex flex-col md:flex-row items-start md:items-center pt-0">
          <!-- Text Section -->
          <div class="w-full md:w-3/4 mb-6 md:mb-0 md:mr-8">
            <h1 class="text-3xl font-bold mb-4 text-gray-900">{{ course.title }}</h1>
            <button @click="handleCourseStart(course.id)" class="bg-primary-700 text-white font-semibold py-2 px-4 rounded-lg shadow hover:bg-secondary-600 transition-colors duration-200 mb-4">
              {{ userProgress === 0 ? 'Start Course' : userProgress === 100 ? 'Course Completed' : 'Continue Course' }}
            </button>
            <div v-if="!canStartCourse" class="text-red-500 font-semibold mt-2">
              Please complete prerequisite courses before starting this course.
            </div>
            <div class="text-md text-gray-700">
              <span class="font-semibold">Category: </span> 

              <span v-if="course.categories && course.categories.length > 0">
                        <span v-for="(category, index) in course.categories" :key="category.id">
                          {{ formatCategoryHierarchy(category) }}{{ index < course.categories.length - 1 ? ', ' : '' }}
                            </span>
                        </span>
                        <span v-else>No Category</span>
                        <br>
              <span class="font-semibold">Course Code:</span> {{ course.course_code || 'No Course Code' }}
            </div>
          </div>

          <!-- Thumbnail and Progress Section -->
          <div class="w-full md:w-1/4">
            <img :src="courseThumbnail" alt="Course Thumbnail" class="w-full object-cover rounded-lg shadow-md mb-4" />
            <ProgressBar :progress="userProgress" />
          </div>
        </section>
      </div>
    </div>

    <!-- Course Completion Banner -->
    <div v-if="isCourseComplete" class="bg-green-500 text-white py-4 px-6 mb-6 text-center rounded-lg">
      <h2 class="text-2xl font-bold">Congratulations! You have completed the course!</h2>
      <p class="text-lg mt-2">Click below to download your certificate.</p>
      <button @click="downloadCertificate"
        class="mt-4 bg-white text-green-500 font-semibold py-2 px-6 rounded-lg shadow hover:bg-gray-200 transition-all duration-300 ease-in-out transform hover:scale-105">
        Download Certificate
        <i class="fas fa-download ml-2"></i>
      </button>
    </div>

    <div class="bg-white">
      <div class="container mx-auto p-8">

        <!-- Course Outline Section -->
        <section class="bg-white p-6 rounded-lg shadow-md">
          <!-- Prerequisite Courses Section -->
          <div v-if="prerequisiteCourses && prerequisiteCourses.length > 0" class="mb-5">
            <h3 class="text-xl font-semibold mb-4">Prerequisite Courses</h3>
            <table class="w-full text-left table-auto">
              <thead>
                <tr>
                  <th class="px-4 py-2">Course</th>
                  <th class="px-4 py-2">Status</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="course in prerequisiteCourses" :key="course.id" class="hover:bg-gray-100">
                  <td class="border px-4 py-2">
                    <router-link :to="{ name: 'ViewCourse', params: { id: course.id } }"
                      class="text-blue-500 hover:underline">
                      {{ course.title }}
                    </router-link>
                  </td>
                  <td class="border px-4 py-2">
                    <span v-if="course.is_complete" class="text-green-500">Completed</span>
                    <span v-else class="text-red-500">Not Completed</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <h2 class="text-2xl font-bold mb-4">Outline</h2>

          <p class="text-md mb-4">{{ course.description }}</p>

          <div class="text-lg font-semibold text-green-600 dark:text-green-400 mb-4">
            <span v-if="course.completion_rules === 'all-units'">
              Complete all units to finish this course and obtain a certificate.
            </span>
            <span v-else-if="course.completion_rules === 'percentage' && course.completion_percentage">
              Complete atleast {{ course.completion_percentage }}% of the course to obtain a certificate.
            </span>
            <span v-else>
              Complete this course to obtain a certificate.
            </span>
          </div>


          <!-- Replace the current units table with the following -->
          <div class="border-t border-gray-300 dark:border-gray-700 pt-6">
  <h3 class="text-xl sm:text-2xl font-semibold mb-6 text-gray-800 dark:text-gray-100">Course Units</h3>

  <!-- ✅ Table for desktop & tablets -->
  <div class="hidden sm:block overflow-x-auto">
    <table class="w-full text-left table-auto border-collapse bg-white dark:bg-gray-800 rounded-lg shadow-md">
      <thead class="bg-gray-100 dark:bg-gray-900 text-gray-700 dark:text-gray-300">
        <tr>
          <th class="px-6 py-4 text-sm font-semibold uppercase tracking-wide">Unit</th>
          <th class="px-6 py-4 text-sm font-semibold uppercase tracking-wide">Type</th>
          <th class="px-6 py-4 text-sm font-semibold uppercase tracking-wide text-center">Status</th>
        </tr>
      </thead>
      <tbody>
        <tr 
          v-for="unit in allUnitsSorted" 
          :key="unit.id" 
          class="hover:bg-gray-50 dark:hover:bg-gray-700 transition-all"
        >
          <td class="border-t border-gray-300 dark:border-gray-700 px-6 py-4 flex items-center text-gray-800 dark:text-gray-200">
            <i :class="getIconClass(unit.unitTypeKey) + ' text-lg mr-3'"></i>
            <span class="truncate">{{ unit.title || 'Test Unit' }}</span>
          </td>
          <td class="border-t border-gray-300 dark:border-gray-700 px-6 py-4 capitalize text-gray-600 dark:text-gray-400">
            {{ unit.unitTypeKey }}
          </td>
          <td class="border-t border-gray-300 dark:border-gray-700 px-6 py-4 text-center">
            <span 
              v-if="unit.is_complete" 
              class="inline-flex items-center text-green-500 font-semibold"
            >
              <i class="fas fa-check-circle mr-2"></i> Completed
            </span>
            <span 
              v-else 
              class="text-red-500 font-semibold"
            >
              Incomplete
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <!-- ✅ Mobile-friendly card layout -->
  <div class="sm:hidden space-y-4">
    <div 
      v-for="unit in allUnitsSorted" 
      :key="unit.id" 
      class="p-4 bg-white dark:bg-gray-800 rounded-lg shadow-md border border-gray-300 dark:border-gray-700"
    >
      <div class="flex items-center mb-2">
        <i :class="getIconClass(unit.unitTypeKey) + ' text-xl text-gray-500 dark:text-gray-300 mr-3'"></i>
        <h4 class="text-lg font-semibold text-gray-900 dark:text-gray-200">
          {{ unit.title || 'Test Unit' }}
        </h4>
      </div>
      <p class="text-gray-600 dark:text-gray-400 text-sm capitalize">
        Type: <span class="font-medium">{{ unit.unitTypeKey }}</span>
      </p>
      <p class="text-sm mt-2">
        <span v-if="unit.is_complete" class="text-green-500 font-semibold flex items-center">
          <i class="fas fa-check-circle mr-2"></i> Completed
        </span>
        <span v-else class="text-red-500 font-semibold">
          Incomplete
        </span>
      </p>
    </div>
  </div>
</div>

          

<!-- Course Resources Section -->
<div v-if="course.resources && course.resources.length > 0" class="mb-8 mt-10">
  <h3 class="text-xl sm:text-2xl font-semibold mb-6 text-gray-800 dark:text-gray-100">Course Resources</h3>

  <div v-for="(resources, type) in categorizedResources" :key="type" class="mb-4">
    <details class="group">
      <summary
        class="cursor-pointer bg-gray-100 dark:bg-gray-800 text-gray-800 dark:text-gray-100 py-3 px-4 rounded-lg shadow hover:bg-gray-200 dark:hover:bg-gray-700 transition"
      >
        {{ formatResourceType(type) }}
        <span class="float-right text-sm text-gray-500 group-open:text-gray-700 dark:group-open:text-gray-300">
          ({{ resources.length }} resources)
        </span>
      </summary>

      <!-- ✅ Table layout for Desktop & Tablets -->
      <div class="hidden sm:block overflow-x-auto">
        <table class="w-full mt-2 text-left table-auto border-collapse border border-gray-300 rounded-lg shadow-sm">
          <thead class="bg-gray-100 text-gray-600 dark:bg-gray-800 dark:text-gray-200">
            <tr>
              <th class="border px-6 py-3 text-sm font-semibold">Resource Name</th>
              <th class="border px-6 py-3 text-sm font-semibold text-center">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr 
              v-for="resource in resources" 
              :key="resource.id" 
              class="hover:bg-gray-50 dark:hover:bg-gray-700 transition-all"
            >
              <td class="border px-6 py-4 text-gray-800 dark:text-gray-200 truncate">{{ resource.name }}</td>
              <td class="border px-6 py-4 text-center">
                <button
                  @click="viewResource(resource)"
                  class="bg-primary-700 hover:bg-secondary-600 text-white text-sm font-medium py-2 px-4 rounded-lg shadow-md hover:shadow-lg transition-all"
                >
                  View Resource
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- ✅ Mobile-friendly stacked card layout -->
      <div class="sm:hidden space-y-4 mt-2">
        <div 
          v-for="resource in resources" 
          :key="resource.id" 
          class="p-4 bg-white dark:bg-gray-800 rounded-lg shadow-md border border-gray-300 dark:border-gray-700"
        >
          <h4 class="text-lg font-semibold text-gray-900 dark:text-gray-200 truncate mb-1">
            {{ resource.name }}
          </h4>
          <p class="text-sm text-gray-600 dark:text-gray-400">
            Type: <span class="font-medium">{{ formatResourceType(type) }}</span>
          </p>
          <button
            @click="viewResource(resource)"
            class="mt-2 w-full bg-primary-700 hover:bg-secondary-600 text-white text-sm font-medium py-2 px-4 rounded-lg shadow-md hover:shadow-lg transition-all"
          >
            View Resource
          </button>
        </div>
      </div>
    </details>
  </div>
</div>




<div v-if="isPopupOpen" class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50" @click.self="closePopup">
  <div class="bg-white rounded-lg shadow-lg overflow-hidden w-11/12 md:w-3/4 max-h-[90%] relative">
    <!-- Header with Close Button -->
    <div class="flex justify-between items-center bg-gray-200 px-4 py-3 border-b">
      <h3 class="text-lg font-semibold truncate">{{ currentResource.name }}</h3>
      <button @click="closePopup" class="text-gray-500 hover:text-gray-700 focus:outline-none" aria-label="Close">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
        </svg>
      </button>
    </div>

    <!-- Content Area -->
    <div class="p-4 overflow-auto max-h-[70vh] bg-gray-50">
      <template v-if="isImage(currentResource.type)">
        <div class="flex justify-center">
          <img :src="getResourceUrl(currentResource.file_path)" alt="Resource Image" class="max-w-full max-h-[60vh] rounded-md shadow-md" @contextmenu.prevent />
        </div>
      </template>
      <template v-else-if="isVideo(currentResource.type)">
        <div class="flex justify-center">
          <video
            :src="getResourceUrl(currentResource.file_path)"
            controls
            controlsList="nodownload"
            disablePictureInPicture
            class="video-modal"
            @contextmenu.prevent
          ></video>
        </div>
      </template>
      
      <template v-else-if="isPDF(currentResource.type)">
        <div 
    ref="pdfContainer" 
    :class="isFullscreen ? 'fixed inset-0 bg-black flex justify-center items-center z-50' : 'relative flex flex-col items-center'" 
    :style="{
      width: isFullscreen ? '100vw' : '100%',
      height: isFullscreen ? '100vh' : '70vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: isFullscreen ? '#000' : '#fff', // Dark background for better visibility
      overflow: 'hidden'
    }"
  >
    <div 
      class="absolute top-0 left-0 w-full flex justify-center items-center bg-secondary-600 text-white py-4 z-10">
      <button 
        @click="prevPage" 
        :disabled="page === 1" 
        class="mx-2 px-4 py-2 bg-gray-700 hover:bg-gray-600 rounded-md disabled:opacity-50 disabled:cursor-not-allowed"
      >
        Prev
      </button>
      <span class="mx-4 text-lg font-semibold">
        {{ page }} / {{ pages }}
      </span>
      <button 
        @click="nextPage" 
        :disabled="page === pages" 
        class="mx-2 px-4 py-2 bg-gray-700 hover:bg-gray-600 rounded-md disabled:opacity-50 disabled:cursor-not-allowed"
      >
        Next
      </button>
      <button 
        @click="toggleFullscreen" 
        class="ml-4 px-4 py-2 bg-gray-700 hover:bg-gray-600 rounded-md"
      >
        {{ isFullscreen ? 'Minimise' : 'Fullscreen' }}
      </button>
    </div>

    <!-- PDF Viewer - Scales to fit both width & height -->
    <div 
      class="flex justify-center items-center"
      :style="{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden'
      }"
    >
      <VuePDF
        :pdf="pdf"
        :page="page"
        :scale="pdfScale"
        class="rounded-md shadow-md"
      />
    </div>
  </div>
</template>
      
      
      
      
      <template v-else-if="currentResource.message">
        <div class="text-center text-gray-600 dark:text-gray-300">{{ currentResource.message }}</div>
      </template>
      <template v-else>
        <iframe
          :src="getResourceUrl(currentResource.file_path)"
          class="w-full h-[60vh] rounded-md shadow-md border"
          frameborder="0"
          @contextmenu.prevent
        ></iframe>
      </template>
    </div>
  </div>
</div>




        </section>
      </div>
    </div>
  </div>
</template>

<script>
import TopNav from '@/components/headers/TopNav.vue';
import ProgressBar from '@/components/ProgressBar.vue';
import axios from 'axios';
import { VuePDF, usePDF } from '@tato30/vue-pdf'

export default {
  components: {
    TopNav,
    ProgressBar,
    VuePDF
  },
  data() {
    return {
      course: {},
      userProgress: 0, // Replace with actual progress data
      previousPage: '', // Stores the previous page
      canStartCourse: true,
      prerequisiteCourses: [], // Holds details of prerequisite courses
      isPopupOpen: false,
      currentResource: {}, // Holds the currently selected resource
      isFullscreen: false, // Track fullscreen mode for PDFs
      page: 1,
      pdf: null,
      pages: 0,
      windowWidth: window.innerWidth, // Track the current window width
      windowHeight: window.innerHeight, // Track the current window height
    };
  },
  computed: {
    calculateScale() {
      // Adjust scale dynamically based on window width
      const baseWidth = 800; // Base width of the PDF content for scale 1
      return this.isFullscreen
        ? Math.min(this.windowWidth / baseWidth, 1.5) // Max scale cap at 1.5
        : 1; // Default scale for normal view
    },
    categorizedResources() {
    if (!this.course.resources) return {};

    // Categorize resources by type
    const grouped = this.course.resources.reduce((acc, resource) => {
      if (!acc[resource.type]) acc[resource.type] = [];
      acc[resource.type].push(resource);
      return acc;
    }, {});

    // Sort resources in each category alphabetically by name
    for (const type in grouped) {
      grouped[type].sort((a, b) => a.name.localeCompare(b.name));
    }

    return grouped;
  },
    courseThumbnail() {
      if (!this.course.thumbnail) {
        return '/img/default-course-image.jpg'; // Fallback to default image
      }

      // Check if the thumbnail is already an absolute URL
      if (this.course.thumbnail.startsWith('http://') || this.course.thumbnail.startsWith('https://')) {
        return this.course.thumbnail;
      }

      // Prepend the base API URL for relative paths
      return `${process.env.VUE_APP_API_URL}${this.course.thumbnail.startsWith('/') ? '' : '/'}${this.course.thumbnail}`;
    },
    isCourseComplete() {
      return (
        (this.course.completion_rules === 'all-units' && this.userProgress === 100) ||
        (this.course.completion_rules === 'percentage' && this.userProgress >= (this.course.completion_percentage || 100))
      );
    },
    allUnitsSorted() {
    if (!this.course || !this.course.units) return [];

    // Flatten all unit types into one array
    let combinedUnits = [];
    for (const [unitTypeKey, unitsArray] of Object.entries(this.course.units)) {
      // Add a unitTypeKey field so we know which icon to use
      const unitsWithType = unitsArray.map(u => ({ ...u, unitTypeKey }));
      combinedUnits = combinedUnits.concat(unitsWithType);
    }

    // Sort combined units by unit_order
    combinedUnits.sort((a, b) => a.unit_order - b.unit_order);

    return combinedUnits;
  }
  },
  watch: {
    'course.title': function (newTitle) {
      if (newTitle) {
        document.title = `${newTitle} | Jam LMS`; // Update page title
      }
    },
    '$route.params.id': {
      immediate: true,
      handler(newId) {
        this.fetchCourse(newId); // Fetch new course data based on the changed ID
      }
    }
  },
  methods: {
    formatCategoryHierarchy(category) {
      let hierarchy = category.name;
      let parent = category.parent;
      while (parent) {
        hierarchy = `${parent.name} > ${hierarchy}`;
        parent = parent.parent;
      }
      return hierarchy;
    },
    formatResourceType(type) {
    const typeMap = {
      'application/pdf': 'PDF Document',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'Presentation',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'Word Document',
      'application/vnd.ms-excel.sheet.macroenabled.12': 'Excel Spreadsheet',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'Excel Spreadsheet',
      'image/png': 'Image (PNG)',
      'image/jpeg': 'Image (JPEG)',
      'image/gif': 'Image (GIF)',
      'video/mp4': 'Video (MP4)',
      'video/webm': 'Video (WebM)',
      'audio/mpeg': 'Audio (MP3)',
      'audio/ogg': 'Audio (OGG)',
    };
    return typeMap[type] || 'Unknown Type';
  },
  isImage(type) {
    const imageTypes = ['jpg', 'jpeg', 'png', 'gif', 'image'];
    return imageTypes.some((imageType) => type.toLowerCase().includes(imageType));
  },
  isVideo(type) {
    const videoTypes = ['mp4', 'webm', 'ogg', 'video'];
    return videoTypes.some((videoType) => type.toLowerCase().includes(videoType));
  },
  isPDF(type) {
    return type.toLowerCase() === 'application/pdf';
  },
  getResourceUrl(filePath) {
    if (!filePath) return '#';
    return `${process.env.VUE_APP_API_URL}${filePath.startsWith('/') ? '' : '/'}${filePath}`;
  },
  async viewResource(resource) {
    this.currentResource = resource;

    if (this.isPDF(resource.type)) {
// Extract the file name from the file path
const fileName = this.currentResource.file_path.split('/').pop();

// Construct the URL using only the file name
const pdfUrl = `${process.env.VUE_APP_API_URL}/api/graphql/download/pdf/${fileName}`;

try {
    const { pdf, pages } = usePDF(pdfUrl);
    this.pdf = pdf;
    this.pages = pages;
    this.page = 1; // Reset to the first page
    this.isPopupOpen = true;
} catch (error) {
    console.error('Error fetching or loading the PDF:', error);
}


  }

    // Check for specific file types
    const unsupportedTypes = [
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // Word
      'application/vnd.openxmlformats-officedocument.presentationml.presentation', // PowerPoint
      'application/vnd.ms-excel.sheet.macroenabled.12', // Excel
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ];

    if (unsupportedTypes.includes(resource.type)) {
      // Trigger file download
      const link = document.createElement('a');
      link.href = this.getResourceUrl(resource.file_path);
      link.download = resource.name || 'download';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Show blank modal with a message
      this.currentResource.message = 'The resource has been downloaded.';
      this.isPopupOpen = true;
      return;
    }

    // For other types, open the modal
    this.isPopupOpen = true;
  },
  toggleFullscreen() {
    this.isFullscreen = !this.isFullscreen;
    this.updateScale();
  },
  updateScale() {

  },
    updateWindowSize() {
    this.windowWidth = window.innerWidth;
    this.windowHeight = window.innerHeight;
    this.updateScale();
  },
  handleEscKey(event) {
    if (event.key === 'Escape' && this.isFullscreen) {
      this.toggleFullscreen();
    }
  },
    closePopup() {
      this.isPopupOpen = false;
      this.currentResource = {};
      this.page = 1; 
      this.pdf = null; 
      this.pages = 0; 
    },
    prevPage() {
      if (this.page > 1) {
        this.page--;
      }
    },
    nextPage() {
      if (this.page < this.pages) {
        this.page++;
      }
    },
  async fetchCourse(courseId) {
    const branchUrl = this.$store.state.branch ? this.$store.state.branch.url : null;
      try {
        const response = await axios.post(`${process.env.VUE_APP_API_URL}/graphql`, {
          query: `query GetCourse($id: Int!, $branchUrl: String) {
              course(id: $id, branchUrl: $branchUrl) {
                id
                title
                thumbnail
                price
                categories {
                  id
                  name
                  parent {
                    id
                    name
                  }
                }
                date
                description
                course_code
                certificate_type
                completion_rules
                completion_percentage
                completed_date
                learning_path
                resources {
                  id
                  name
                  file_path
                  type
                }
                units {
                  contents {
                    id
                    title
                    unit_order
                    is_complete
                  }
                  videos {
                    id
                    title
                    unit_order
                    is_complete
                  }
                  audios {
                    id
                    title
                    unit_order
                    is_complete
                  }
                  documents {
                    id
                    title
                    unit_order
                    is_complete
                  }
                  iframes {
                    id
                    title
                    unit_order
                    is_complete
                  }
                  scorms {
                    id
                    title
                    unit_order
                    is_complete
                  }
                  tests {
                    id
                    unit_order
                    is_complete
                  }
                  assignments {
                    id
                    title
                    unit_order
                    is_complete
                  }
                }
              }
            }`,
            variables: {
              id: parseInt(courseId),
              branchUrl: branchUrl || null
            },
        });

        this.course = response.data.data.course;
        document.title = `${this.course.title} (Overview) | Jam LMS`;
        this.userProgress = this.calculateUserProgress();
        this.checkLearningPathCompletion();

      } catch (error) {
        console.error('Error fetching course:', error);
      }
    },
    async checkLearningPathCompletion() {
      if (!this.course.learning_path || !Array.isArray(this.course.learning_path) || this.course.learning_path.length === 0) {
        this.canStartCourse = true;
        this.prerequisiteCourses = [];
        return;
      }

      try {
        const response = await axios.post(`${process.env.VUE_APP_API_URL}/graphql`, {
          query: `
        query CompletedCourses($ids: [Int!]) {
          CompletedCourses(ids: $ids) {
            id
            title
            is_complete
            certificate_type
          }
        }
      `,
          variables: {
            ids: this.course.learning_path,
          },
        });

        // Ensure case consistency with response data
        this.prerequisiteCourses = response.data.data.CompletedCourses;
        this.canStartCourse = this.prerequisiteCourses.every(course => course.is_complete);

      } catch (error) {
        console.error('Error checking learning path completion:', error);
        this.canStartCourse = false;
        document.title = 'Preview Course | Jam LMS';
      }
    },

    async handleCourseStart(courseID) {
      try {
        // Call the CourseEnrollment mutation to enroll the user
        await axios.post(`${process.env.VUE_APP_API_URL}/graphql`, {
          query: `
            mutation CourseEnrollment($input: CourseEnrollmentInput!) {
              CourseEnrollment(input: $input) {
                id
                title
              }
            }
          `,
          variables: {
            input: {
              course_id: courseID,
            },
          },
        });

        // After successful enrollment, navigate to the first unit
        this.startCourse(courseID);

      } catch (error) {
        console.error('Error enrolling in course:', error);
      }
    },
    startCourse(courseID) {
      // Navigate to the course page with the first unit when "Start Course" is clicked
      this.$router.push({
        name: 'course',
        params: { course: courseID, id: 1 }, // Navigate to the course page with the first unit
      });
    },
    calculateUserProgress() {
      if (!this.course.units || typeof this.course.units !== 'object') {
        return 0;
      }

      const allUnits = Object.values(this.course.units).flat();
      const completedUnits = allUnits.filter(unit => unit.is_complete).length;
      const totalUnits = allUnits.length;

      return totalUnits ? Math.round((completedUnits / totalUnits) * 100) : 0;
    },
    getIconClass(unitType) {
      const icons = {
        contents: 'fas fa-file-alt',
        videos: 'fas fa-video',
        audios: 'fas fa-music',
        documents: 'fas fa-file-pdf',
        iframes: 'fas fa-globe',
        scorms: 'fas fa-laptop',
        tests: 'fas fa-pencil-alt',
        assignments: 'fas fa-tasks',
      };
      return icons[unitType] || 'fas fa-file';
    },
    async downloadCertificate() {
      try {
        // Convert completed_date to a Date object for processing expiry_date
        const completedDate = new Date(this.course.completed_date);

        // Calculate the expiry date (one year from completed date)
        const expiryDate = new Date(completedDate.setFullYear(completedDate.getFullYear() + 1));

        // Format dates to ISO 8601 (yyyy-mm-dd) for GraphQL
        const formatDateISO = (date) => date.toISOString().split('T')[0];

        const formattedCompletedDate = formatDateISO(new Date(this.course.completed_date));
        const formattedExpiryDate = formatDateISO(expiryDate);

        // Call the generateCertificate GraphQL mutation
        const response = await axios.post(`${process.env.VUE_APP_API_URL}/graphql`, {
          query: `
        mutation GenerateCertificate($input: GenerateCertificateInput!) {
          GenerateCertificate(input: $input) {
            message
            certificate_url
          }
        }
      `,
          variables: {
            input: {
              user_name: `${this.$store.state.user.first_name} ${this.$store.state.user.last_name}`,
              course_name: this.course.title,
              issued_date: formattedCompletedDate,
              expiry_date: formattedExpiryDate,
              certificate_type: this.course.certificate_type || "Default",
            },
          },
        });

        const { certificate_url } = response.data.data.GenerateCertificate;

        // Trigger the download of the certificate
        const link = document.createElement('a');
        link.href = certificate_url;
        link.download = 'certificate.pdf';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

      } catch (error) {
        console.log('Error generating certificate:', error);
      }
    },

    goBack() {
      if (this.previousPage === 'myCourses') {
        this.$router.push({ name: 'My Courses' });
      } else {
        this.$router.push({ name: 'Course Catalog' });
      }
    },
  },
  mounted() {
    const courseId = this.$route.params.id;
    this.fetchCourse(courseId);

    // Check the previous route
    const fromRoute = this.$router.currentRoute.value.query.from;
    this.previousPage = fromRoute === 'myCourses' ? 'myCourses' : 'courseCatalog';
    window.addEventListener('resize', this.updateWindowSize);
    window.addEventListener('keydown', this.handleEscKey);


  },
  beforeUnmount() {
    window.removeEventListener('resize', this.updateWindowSize);
    window.removeEventListener('keydown', this.handleEscKey);

  },
};
</script>

<style scoped>
.bg-light-blue {
  background-color: #f0f8ff;
}

.container {
  max-width: 1200px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

table th,
table td {
  border: 1px solid #ddd;
  padding: 8px;
}

table th {
  background-color: #f2f2f2;
}

.table-auto tbody tr:hover {
  background-color: #f9f9f9;
}

.fas {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}

.bg-green-500 {
  background-color: #38a169;
}

.text-green-500 {
  color: #38a169;
}

.text-red-500 {
  color: #e53e3e;
}

button:hover {
  transform: scale(1.05);
}
details {
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  margin-bottom: 8px;
  padding: 0;
}

summary {
  font-size: 1rem;
  font-weight: 500;
}

details[open] summary {
  background-color: #edf2f7;
  color: #2d3748;
}

details[open] summary:hover {
  background-color: #e2e8f0;
}

.group-open {
  font-weight: bold;
}
.bg-light-blue {
  background-color: #f0f8ff;
}
.fixed-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 50;
  background-color: #1a202c; /* Dark gray */
  color: #fff;
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.object-viewer {
  margin-top: 4rem; /* Adjust for header height */
}
</style>

