<template>
    <div class="mt-8 mx-4">
      <div
        class="p-4 bg-blue-50 dark:bg-gray-800 dark:text-gray-50 border border-blue-500 dark:border-gray-500 rounded-lg shadow-md"
      >
        <h4 class="text-lg font-semibold">Quick Links:</h4>
        <ul v-if="!$store.getters.isManager">
          <li class="mt-3">
            <router-link
              to="/learner/course-catalog"
              class="flex items-center text-blue-700 dark:text-gray-100"
            >
              <svg
                width="20"
                height="20"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                class="transform transition-transform duration-500 ease-in-out"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              <span class="inline-flex pl-2">Learner Course Catalog</span>
            </router-link>
          </li>
          <li class="mt-2">
            <router-link
              to="/groups"
              class="flex items-center text-blue-700 dark:text-gray-100"
            >
              <svg
                width="20"
                height="20"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                class="transform transition-transform duration-500 ease-in-out"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              <span class="inline-flex pl-2">Groups</span>
            </router-link>
          </li>
          <li class="mt-2">
            <a
              class="flex items-center text-blue-700 dark:text-gray-100"
              href="#"
            >
              <svg
                width="20"
                height="20"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                class="transform transition-transform duration-500 ease-in-out"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              <span class="inline-flex pl-2">Support</span>
            </a>
          </li>
          <li class="mt-2">
            <router-link
              to="/reports/users"
              class="flex items-center text-blue-700 dark:text-gray-100"
            >
              <svg
                width="20"
                height="20"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                class="transform transition-transform duration-500 ease-in-out"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              <span class="inline-flex pl-2">Reporting</span>
            </router-link>
          </li>
          <li class="mt-2">
            <router-link
              to="/learner/dashboard"
              class="flex items-center text-blue-700 dark:text-gray-100"
            >
              <svg
                width="20"
                height="20"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                class="transform transition-transform duration-500 ease-in-out"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              <span class="inline-flex pl-2">Learner Dashboard</span>
            </router-link>
          </li>
        </ul>

        <ul v-else>
          <li class="mt-3">
            <router-link
              to="/learner/course-catalog"
              class="flex items-center text-blue-700 dark:text-gray-100"
            >
              <svg
                width="20"
                height="20"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                class="transform transition-transform duration-500 ease-in-out"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              <span class="inline-flex pl-2">Learner Course Catalog</span>
            </router-link>
          </li>
          <li class="mt-2">
            <router-link
              to="/learner/certificates"
              class="flex items-center text-blue-700 dark:text-gray-100"
            >
              <svg
                width="20"
                height="20"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                class="transform transition-transform duration-500 ease-in-out"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              <span class="inline-flex pl-2">Learner Certificates</span>
            </router-link>
          </li>
          <li class="mt-2">
            <a
              class="flex items-center text-blue-700 dark:text-gray-100"
              href="#"
            >
              <svg
                width="20"
                height="20"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                class="transform transition-transform duration-500 ease-in-out"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              <span class="inline-flex pl-2">Support</span>
            </a>
          </li>
          <li class="mt-2">
            <router-link
              to="/profile"
              class="flex items-center text-blue-700 dark:text-gray-100"
            >
              <svg
                width="20"
                height="20"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                class="transform transition-transform duration-500 ease-in-out"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              <span class="inline-flex pl-2">User Profile</span>
            </router-link>
          </li>
          <li class="mt-2">
            <router-link
              to="/learner/dashboard"
              class="flex items-center text-blue-700 dark:text-gray-100"
            >
              <svg
                width="20"
                height="20"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                class="transform transition-transform duration-500 ease-in-out"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              <span class="inline-flex pl-2">Learner Dashboard</span>
            </router-link>
          </li>
        </ul>
    </div>
    </div>
  </template>

<script>

export default {
  methods: {
  },
};
</script>
