<template>
  <div>
    <TopNav @toggle-sidebar="toggleSidebar"  />
    <SideMenu :isOpen="isSidebarOpen" @close-sidebar="isSidebarOpen = false" />
    <main class="p-6 md:ml-64 h-auto pt-20 bg-gray-50">
      <div class="mx-4 min-h-screen max-w-screen-xl sm:mx-8 xl:mx-auto">
        <!-- Header -->
        <div class="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-6 bg-white shadow-md rounded-lg p-6">
          <div>
            <h1 class="text-4xl font-bold text-gray-800 mb-2">User Reports</h1>
            <p class="text-gray-500 text-sm">Track and manage user activity across the platform</p>
          </div>
          <!-- <button class="text-white px-6 py-2 rounded-lg shadow-lg focus:outline-none bg-secondary-700">
            Export Report
          </button> -->
        </div>

        <div v-if="loadingStats" class="text-center py-10">
          <svg aria-hidden="true" class="w-14 h-14 mx-auto text-gray-200 animate-spin dark:text-gray-600 fill-secondary-600"
            viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor" />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill" />
          </svg>
          <span class="sr-only">Loading...</span>
        </div>
      <div v-else >
        <!-- Statistics Section -->
        <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mb-8">
          <StatCard title="Completion Rate" :value="stats.completionRate + '%'" icon="completion-rate" />
          <StatCard title="Completed Courses" :value="stats.completedCourses" icon="completed-courses" />
          <StatCard title="Courses in Progress" :value="stats.coursesInProgress" icon="in-progress" />
          <StatCard title="Courses Not Started" :value="stats.coursesNotStarted" icon="not-started" />
          <StatCard title="Training Time" :value="stats.trainingTime" icon="training-time" />
        </div>

        <!-- Search and Table Section -->
        <div>
          <!-- Search Bar -->
          <div class="flex items-center justify-between mb-4">
            <input
              v-model="searchQuery"
              type="text"
              placeholder="Search users by name or email..."
              class="w-full sm:w-1/3 px-4 py-3 border border-gray-300 rounded-lg focus:ring focus:ring-blue-300 focus:border-blue-500 text-gray-800"
            />
            <button
              @click="resetSearch"
              v-if="searchQuery"
              class="text-sm text-gray-500 underline hover:text-gray-700 ml-4"
            >
              Clear Search
            </button>
          </div>

          <!-- Users Table -->
          <div class="overflow-hidden rounded-lg border border-gray-200 shadow-sm">
            <table class="min-w-full divide-y divide-gray-200 bg-white">
              <!-- Table Head -->
              <thead class="bg-gray-100">
                <tr>
                  <th class="px-6 py-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Name</th>
                  <th class="px-6 py-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Email</th>
                  <th class="px-6 py-4 text-center text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    Completed Courses
                  </th>
                  <th class="px-6 py-4 text-center text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    In Progress
                  </th>
                  <th class="px-6 py-4 text-center text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    Not Started
                  </th>
                  <th class="px-6 py-4 text-right text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    Details
                  </th>
                </tr>
              </thead>

              <!-- Table Body -->
              <tbody class="divide-y divide-gray-200">
                <tr
                  v-for="user in filteredUsers"
                  :key="user.id"
                  class="hover:bg-blue-50 cursor-pointer transition"
                  @click="viewUserDetails(user.id)"
                >
                  <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800">
                    {{ user.name }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                    {{ user.email }}
                  </td>
                  <td
                    class="px-6 py-4 whitespace-nowrap text-center text-sm font-semibold"
                    :class="{
                      'text-green-600': user.completedCourses > 0,
                      'text-gray-400': user.completedCourses === 0,
                    }"
                  >
                    {{ user.completedCourses }}
                  </td>
                  <td
                    class="px-6 py-4 whitespace-nowrap text-center text-sm font-semibold"
                    :class="{
                      'text-yellow-600': user.coursesInProgress > 0,
                      'text-gray-400': user.coursesInProgress === 0,
                    }"
                  >
                    {{ user.coursesInProgress }}
                  </td>
                  <td
                    class="px-6 py-4 whitespace-nowrap text-center text-sm font-semibold"
                    :class="{
                      'text-red-500': user.coursesNotStarted > 0,
                      'text-gray-400': user.coursesNotStarted === 0,
                    }"
                  >
                    {{ user.coursesNotStarted }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-right text-sm">
                    <button
                      class="text-primary-700 hover:text-blue-800"
                      @click.stop="viewUserDetails(user.id)"
                    >
                      View Details
                    </button>
                  </td>
                </tr>
                <tr v-if="!filteredUsers.length">
                  <td colspan="6" class="text-center py-8 text-gray-500 text-sm">
                    No users found. Try refining your search.
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div></div>
    </main>
  </div>
</template>

<script>
import TopNav from "@/components/headers/TopNav.vue";
import SideMenu from "@/components/SideMenu.vue";
import StatCard from "@/components/Reports/StatCard.vue";
import axios from "axios";

export default {
  components: {
    TopNav,
    SideMenu,
    StatCard,
  },
  data() {
    return {
      stats: {},
      users: [],
      searchQuery: "",
      loadingStats: true,
      isSidebarOpen: false,
    };
  },
  computed: {
    filteredUsers() {
      if (!this.searchQuery) return this.users;
      return this.users.filter(
        (user) =>
          user.name.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
          user.email.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    },
  },
  methods: {
    toggleSidebar() {
            this.isSidebarOpen = !this.isSidebarOpen;
        },
    async fetchReportsAllUsers() {
      const branchUrl = this.$store.state.branch ? this.$store.state.branch.url : null; // Retrieve branchUrl if available
      try {
    this.loadingStats = true;

    const response = await axios.post(`${process.env.VUE_APP_API_URL}/graphql`, {
      query: `
        query GetReportsAllUsers($branchUrl: String) {
          ReportsAllUsers(branchUrl: $branchUrl) {
            stats {
              completionRate
              completedCourses
              coursesInProgress
              coursesNotStarted
              trainingTime
            }
            users {
              id
              name
              email
              completedCourses
              coursesInProgress
              coursesNotStarted
            }
          }
        }
      `,
      variables: { branchUrl },
    });

    const data = response.data.data.ReportsAllUsers;
    this.stats = data.stats;
    this.users = data.users;
  } catch (error) {
    console.error("Error fetching user reports:", error);
  } finally {
    this.loadingStats = false;
  }
},
    viewUserDetails(userId) {
      this.$router.push({ name: "UserReport", params: { id: userId } });
    },
    resetSearch() {
      this.searchQuery = "";
    },
  },
  mounted() {
    this.fetchReportsAllUsers();
  },
};
</script>

<style>
/* Table Styling */
table th,
table td {
  text-align: left;
  padding: 0.75rem 1.5rem;
}

table th {
  background-color: #f9fafb;
  color: #4b5563;
  text-transform: uppercase;
}

table tr:nth-child(even) {
  background-color: #f8fafc;
}

table tr:hover {
  background-color: #ebf5ff;
}

/* Button Styling */
button {
  transition: color 0.2s, background-color 0.2s;
}
</style>
