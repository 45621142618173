<template>
    <div class="antialiased bg-gray-50 dark:bg-gray-900">
      <TopNav @toggle-sidebar="toggleSidebar" ></TopNav>
  
      <!-- Sidebar -->
      <SideMenu :isOpen="isSidebarOpen" @close-sidebar="isSidebarOpen = false"></SideMenu>
  
      <main class="p-4 md:ml-64 h-auto pt-20">
        <!-- grid 1 -->
        <div class="flex flex-row mb-4 mt-3">
          <!-- Main content area with variable grid layout -->
          <div class="basis-full lg:basis-10/12 mx-auto max-w-screen-3xl pr-5">
            <!-- Responsive grid layout -->
            <div class="mt-4 grid grid-cols-12 gap-4 md:grid-cols-12 md:gap-6 2xl:mt-7.5 2xl:gap-7.5">
              
              <!-- User Activity Analytics -->

              <div class="col-span-12 sm:col-span-5 md:col-span-12 xl:col-span-12 2xl:col-span-7 rounded-sm border border-stroke bg-white px-5 pb-5 pt-7.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5">

              <UserActivityAnalytics 
                title="How am I doing?" 
                :chartData="chartData" 
                :chartLabels="chartLabels" 
                :activeUsers="activeUsers" 
                :inactiveUsers="inactiveUsers"
                :trainingTime="trainingTime" 
                :completedCourses="completedCourses" 
                :totalCourses="totalCourses"
              />

            </div>
  
              <!-- Quick Links -->
              <div class="col-span-12 sm:col-span-5 md:col-span-12 xl:col-span-12 2xl:col-span-5 rounded-sm border border-stroke bg-white p-7.5 pb-2 shadow-default dark:border-strokedark dark:bg-boxdark">
                <QuickLinks title="Quick Links"></QuickLinks>
              </div>
  
              <!-- Course Overview -->
              <div class="col-span-12 sm:col-span-5 md:col-span-12 xl:col-span-12 2xl:col-span-6  rounded-sm border border-stroke bg-white p-7.5 shadow-default dark:border-strokedark dark:bg-boxdark">
                <CourseOverview
                title="Recent Courses"
                :courses="recentCourses"
              />
                          </div>
  
              <!-- Recent Users -->
              <div class="col-span-12 sm:col-span-5 md:col-span-12 xl:col-span-12 2xl:col-span-6  rounded-sm border border-stroke bg-white px-5 pb-5 pt-7.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5">
                <BasicUserTable
                title="Recent Users"
                :users="recentUsers"
              />
            </div>
            </div>
          </div>
          
          <!-- Sidebar on the right (account management section) -->
          <div class="hidden lg:block lg:basis-2/12 border-l pl-4 pt-5 min-w-[300px]">
            <h3 class="font-semibold text-lg">Your Account</h3>
            <CreditsSubscriptions title="Credits & Subscriptions" :subscriptions="subscriptions" ></CreditsSubscriptions>
            <TrainingURL title="Your 'Training branch URL'" :trainingURL="trainingURL"></TrainingURL>
            <FAQList></FAQList>
          </div>
        </div>
      </main>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import TopNav from '@/components/headers/TopNav.vue';
  import SideMenu from '@/components/SideMenu.vue';
  import BasicUserTable from '@/components/dashboard/BasicUserTable.vue';
  import CreditsSubscriptions from '@/components/dashboard/CreditsSubscriptions.vue';
  import FAQList from '@/components/dashboard/FAQList.vue';
  import TrainingURL from '@/components/dashboard/TrainingURL.vue';
  import UserActivityAnalytics from '@/components/dashboard/UserActivityAnalytics.vue';
  import QuickLinks from '@/components/dashboard/QuickLinks.vue';
  import CourseOverview from '@/components/dashboard/CourseOverview.vue';
  
  export default {
    components: {
      TopNav,
      SideMenu,
      BasicUserTable,
      CreditsSubscriptions,
      FAQList,
      TrainingURL,
      UserActivityAnalytics,
      QuickLinks,
      CourseOverview,
    },
    data() {
      return {
        analyticsData: null,
      recentActivities: [],
      activities: [],
      alerts: [],
      quickLinks: [],
      accountData: null,
      subscriptions: {
        activeCourses: 0,
        activeUsers: 0
      },
      trainingURL: '',
      faqList: [],
      recentCourses: [],
      recentUsers: [],
      loading: true,
      error: null,
      chartData: [],
      chartLabels: [],
      trainingTime: 0,
      completedCourses: 0, 
      totalCourses: 0,
      isSidebarOpen: false,
      };
    },
    methods: {
      toggleSidebar() {
            this.isSidebarOpen = !this.isSidebarOpen;
        },
      async fetchDashboardData() {
  const branchUrl = this.$store.state.branch ? this.$store.state.branch.url : null;

  try {
    this.loading = true;

    const response = await axios.post(`${process.env.VUE_APP_API_URL}/graphql`, {
      query: `
        query DashboardData($branchUrl: String) {
          dashboardData(branchUrl: $branchUrl) {
            recentUsers {
              id
              name
              email
              type
              assignedCourses
              completedCourses
            }
            recentCourses {
              id
              name
              users
            }
            analyticsData {
              completionRate
              completedCourses
              inProgressCourses
              notStartedCourses
              trainingTime
              assignedCourses
              activeUsers
              inactiveUsers
              totalCourses
            }
          }
        }
      `,
      variables: { branchUrl },
    });

    if (response.data.errors) {
      console.error('GraphQL errors:', response.data.errors);
      this.error = 'Error fetching dashboard data.';
      this.loading = false;
      return;
    }

    const data = response.data.data.dashboardData;

    if (!data || !data.analyticsData) {
      console.error('Missing data in response:', data);
      this.error = 'Error fetching dashboard data.';
      this.loading = false;
      return;
    }

    // Extract data for UserActivityAnalytics
    const chartData = [
      data.analyticsData.completedCourses,
      data.analyticsData.inProgressCourses,
      data.analyticsData.notStartedCourses,
    ];
    const chartLabels = [
      'Completed',
      'In Progress',
      'Not Started',
    ];

      this.subscriptions = {
        activeCourses: data.analyticsData.totalCourses,
        activeUsers: data.analyticsData.activeUsers
      };

    // Populate UserActivityAnalytics props
    this.chartData = chartData;
    this.chartLabels = chartLabels;
    this.activeUsers = data.analyticsData.activeUsers
    this.inactiveUsers = data.analyticsData.inactiveUsers
    this.trainingTime = data.analyticsData.trainingTime;
    this.completedCourses = data.analyticsData.completedCourses;
    this.trainingURL = window.location.hostname || '';
    this.totalCourses = data.analyticsData.totalCourses;
    this.recentCourses = data.recentCourses || [];
    this.recentUsers = data.recentUsers || [];

    this.loading = false;
  } catch (err) {
    console.error("Error fetching data:", err);
    this.error = 'Failed to fetch dashboard data.';
    this.loading = false;
  }
}


    },
    mounted() {
      this.fetchDashboardData();
    },
  };
  </script>
  