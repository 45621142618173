<template>
  <div class="flex justify-center">
    <!-- Main container -->
    <div class="w-full max-w-7xl mx-4 mt-8">
      <!-- Course Card -->
      <div class="p-8 mb-8">
        <h1 class="text-4xl mb-6 font-semibold text-gray-800">Course Overview</h1>
        <div class="flex mb-6 group">
          <!-- Thumbnail -->
          <div class="relative w-2/6 h-64 mr-6 overflow-hidden">
            <!-- Hidden File Input -->
            <input 
              type="file" 
              accept="image/*" 
              class="hidden" 
              ref="thumbnailInput" 
              @change="handleThumbnailUpload"
            />
            <!-- Thumbnail Preview -->
            <img
              :src="thumbnailPreview || '/img/default-course-image.jpg'"
              alt="Course Thumbnail"
              class="w-full h-full object-cover rounded-md transition duration-300 transform hover:scale-105 cursor-pointer"
              @click="triggerThumbnailUpload"
            />
          </div>
          <!-- Title and Description -->
          <div class="flex-1">
            <input
              type="text"
              v-model="courseTitle"
              class="w-full border-b border-gray-300 focus:outline-none focus:border-primary-500 mb-6"
              placeholder="New Course Title"
              @input="emitCourseData"
            />
            <textarea
              v-model="courseDescription"
              class="w-full border border-gray-300 rounded-md h-24 resize-none py-2 px-4 mb-6 focus:outline-none focus:border-primary-500"
              placeholder="Course Description"
              @input="emitCourseData"
            ></textarea>
          </div>
        </div>

        <!-- Catalog Section -->
        <div class="mb-6">
          <h3 class="text-lg font-semibold mb-4">Catalog</h3>
          <div class="grid grid-cols-2 gap-4">
            <input
            v-model="courseCode"
            type="text"
            class="w-full border border-gray-300 rounded-md py-0 px-4 focus:outline-none focus:border-primary-500 appearance-none h-auto"
            placeholder="Course Code"
            @input="emitCourseData"
            
          />

            <input
              v-model="price"
              type="number"
              class="w-full border border-gray-300 rounded-md py-2 px-4 focus:outline-none focus:border-primary-500"
              placeholder="Price"
              @input="emitCourseData"
            />
            <div class="flex items-center">
              <label>
                <input
                  v-model="hideFromCatalog"
                  type="checkbox"
                  class="form-checkbox h-5 w-5 text-primary-500 rounded"
                  @change="emitCourseData"
                />
                <span class="ml-2">Hide from Course Catalog</span>
              </label>
            </div>
          </div>
        </div>

                <!-- Category Multi-Select with Parent Display -->
          <div class="relative mt-6 mb-6">
          <h3 class="text-lg font-semibold mb-2">Categories</h3>
          <div class="flex flex-wrap gap-2 bg-white border border-gray-300 rounded-md py-2 px-4 focus-within:border-primary-500 min-h-[48px]">
            <div
              v-for="(cat, index) in selectedCategories"
              :key="index"
              class="bg-blue-100 text-blue-800 px-2 py-1 rounded-md flex items-center"
            >
              {{ cat.parent ? `${cat.parent.name} > ${cat.name}` : cat.name }}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-4 w-4 ml-1 cursor-pointer"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                @click.stop="removeCategory(cat)"
              >
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"/>
              </svg>
            </div>
            <input
              type="text"
              ref="categoryInput"
              v-model="searchCategory"
              class="flex-1 border-none outline-none"
              placeholder="Search or create a category..."
              @focus="showCategoryDropdown = true"
              @input="filterCategories"
            />
          </div>

          <!-- Category Dropdown -->
          <div
            ref="categoryDropdown"
            v-show="showCategoryDropdown"
            class="absolute bg-white border border-gray-300 mt-1 rounded-md shadow-lg w-full max-h-40 overflow-auto z-10"
          >
            <ul>
              <li
              v-for="cat in filteredCategories"
              :key="cat.id"
              @click="selectCategory(cat)"
              class="p-2 hover:bg-gray-200 cursor-pointer"
              >
              {{ cat.parent && cat.parent.name ? `${cat.parent.name} > ${cat.name}` : cat.name }}
            </li>


              <li
                v-if="searchCategory && !filteredCategories.some(cat => cat.name.toLowerCase() === searchCategory.toLowerCase())"
                @click="prepareNewCategory"
                class="p-2 text-blue-500 hover:bg-gray-200 cursor-pointer"
              >
                + Add "{{ searchCategory }}"
              </li>
              <li v-if="filteredCategories.length === 0" class="p-2 text-gray-500">
                No Categories Found
              </li>
            </ul>
          </div>
        </div>

        <!-- Add New Category Modal -->
        <div v-if="showCreateCategory" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div class="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6 max-w-lg w-full">
            <h3 class="text-lg font-semibold text-gray-800 dark:text-gray-100 mb-4">Add New Category</h3>
            <input
              v-model="newCategoryName"
              type="text"
              class="w-full border border-gray-300 rounded-md py-2 px-4 focus:outline-none focus:border-primary-500"
              placeholder="Category Name"
            />
            <select v-model="newCategoryParent" class="w-full mt-4 border border-gray-300 rounded-md py-2 px-4">
              <option value="">No Parent</option>
              <option v-for="cat in categories" :key="cat.id" :value="cat.id">
                {{ cat.name }}
              </option>
            </select>
            <div class="flex justify-end space-x-4 mt-6">
              <button @click="closeCategoryModal" class="px-4 py-2 bg-gray-500 text-white rounded-lg">Cancel</button>
              <button @click="addNewCategory" class="px-4 py-2 bg-green-600 text-white rounded-lg">Create</button>
            </div>
          </div>
        </div>

        <!-- Access Options Section -->
        <div class="mb-6">
          <h3 class="text-lg font-semibold mb-4">Access Options</h3>
          <div class="grid grid-cols-2 gap-4">
            <input
              v-model="capacity"
              type="number"
              class="w-full border border-gray-300 rounded-md py-2 px-4 focus:outline-none focus:border-primary-500"
              placeholder="Capacity"
              @input="emitCourseData"
            />
            <input
              v-model="level"
              type="text"
              class="w-full border border-gray-300 rounded-md py-2 px-4 focus:outline-none focus:border-primary-500"
              placeholder="Level"
              @input="emitCourseData"
            />
          </div>
        </div>

        <!-- Time Options Section -->
        <div class="mb-6">
          <h3 class="text-lg font-semibold mb-1">Time Options</h3>
          <p class="mb-4">Schedule a time when the course will be publicly available.</p>
          <div class="grid grid-cols-2 gap-4">
            <label>Start Date
            <input
              v-model="startDate"
              type="date"
              class="w-full border border-gray-300 rounded-md py-2 px-4 focus:outline-none focus:border-primary-500"
              placeholder="Start Date"
              @input="emitCourseData"
            /></label>
            <label>Start Time
            <input
              v-model="startTime"
              type="time"
              class="w-full border border-gray-300 rounded-md py-2 px-4 focus:outline-none focus:border-primary-500"
              placeholder="Start Time"
              @input="emitCourseData"
            /></label>
            <label>End Date
            <input
              v-model="endDate"
              type="date"
              class="w-full border border-gray-300 rounded-md py-2 px-4 focus:outline-none focus:border-primary-500"
              placeholder="End Date"
              @input="emitCourseData"
            />
            </label>
            <label>End Time
            <input
              v-model="endTime"
              type="time"
              class="w-full border border-gray-300 rounded-md py-2 px-4 focus:outline-none focus:border-primary-500"
              placeholder="End Time"
              @input="emitCourseData"
            /></label>
          </div>
        </div>

        <!-- Course Completion Rules Section -->
        <!-- Course Completion Rules Section -->
        <div class="mb-6">
          <h3 class="text-lg font-semibold mb-4">Course Completion Rules</h3>
          <select
            v-model="completionRules"
            class="w-full border border-gray-300 rounded-md py-2 px-4 focus:outline-none focus:border-primary-500"
            @change="emitCourseData"
          >
            <option value="all-units">All units are completed</option>
            <option value="percentage">A percentage of the units is completed</option>
          </select>

          <!-- Conditionally render the percentage input if "percentage" is selected -->
          <div v-if="completionRules === 'percentage'" class="mt-4 flex items-center">
            <input
              v-model="completionPercentage"
              type="number"
              min="0"
              max="100"
              class="w-full max-w-sm border border-gray-300 rounded-md py-2 px-4 border-r-0 focus:outline-none focus:border-primary-500"
              placeholder="Enter percentage"
              @input="emitCourseData"
            />
            <span class="font-bold border border-gray-300 rounded-md py-2 px-4 focus:outline-none focus:border-primary-500">%</span>
          </div>
        </div>

<!-- Learning Path Section -->
<div class="mb-6 relative">
  <h3 class="text-lg font-semibold mb-4">Learning Path</h3>
  <p class="text-gray-700 mb-4">
    A learning path helps organise courses in a specific order, guiding learners from foundational to advanced topics. 
    You can set prerequisites, ensuring learners complete key courses before moving on to this one.    
  </p>
  
  <div
    ref="learningPathInput"
    class="relative flex flex-wrap items-start bg-white border border-gray-300 rounded-md py-2 px-4 focus-within:border-primary-500 min-h-[48px]"
    @click="toggleLearningPathDropdown"
  >
    <div class="flex flex-wrap gap-1 items-center">
      <!-- Render selected courses as tags -->
      <div
        v-for="course in learningPath"
        :key="course.id"
        class="bg-blue-100 text-blue-800 px-2 py-1 rounded-md flex items-center"
      >
        <span>{{ course.title }}</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-4 w-4 ml-1 cursor-pointer"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          @click.stop="removeCourse(course)"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </div>
    </div>
    <!-- Dropdown Icon -->
    <div @click="toggleLearningPathDropdown" class="absolute top-2 right-2 cursor-pointer">
      <svg @click="toggleLearningPathDropdown"
        xmlns="http://www.w3.org/2000/svg"
        class="h-5 w-5 text-gray-600"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M19 9l-7 7-7-7"
        />
      </svg>
    </div>
  </div>

  <!-- Dropdown Menu -->
  <div
    ref="learningPathDropdown"
    v-show="showLearningPathDropdown"
    class="absolute bg-white border border-gray-300 mt-1 rounded-md shadow-lg w-full max-h-40 overflow-auto z-10"
  >
    <input
      v-model="learningPathSearchTerm"
      type="text"
      class="w-full border-b border-gray-300 py-2 px-4 focus:outline-none"
      placeholder="Search for courses"
      @input="filterLearningPathCourses"
    />
    
    <ul>
      <li
        v-for="course in filteredLearningPathCourses"
        :key="course.id"
        @click="selectCourse(course)"
        class="p-2 hover:bg-gray-200 cursor-pointer"
      >
        {{ course.title }}
      </li>
      <li v-if="filteredLearningPathCourses.length === 0" class="p-2 text-gray-500">No Courses Found</li>
    </ul>
  </div>
</div>



        <!-- Certificate Info Section -->
        <div>
          <h3 class="text-lg font-semibold mb-4">Certificate Info</h3>
          <div class="flex items-center mb-4">
            <span class="mr-2">Type:</span>
            <select
              v-model="certificateType"
              class="border border-gray-300 rounded-md py-2 px-4 focus:outline-none focus:border-primary-500"
              @change="emitCourseData"
            >
              <option value="Default">Default</option>
              <option value="SaferInternetDay">Safer Internet Day</option>
            </select>
          </div>
        </div>

<!-- Resources Upload Section -->
<div class="mb-6">
  <h3 class="text-lg font-semibold mb-4">Course Resources</h3>

  <div class="flex flex-wrap gap-4 mb-4">
    <!-- Resource Preview -->
    <div
      v-for="(resource, index) in uploadedResources"
      :key="index"
      class="relative w-32 h-32 bg-gray-100 rounded-lg flex items-center justify-center overflow-hidden"
    >
      <!-- File Preview (PDF or Image) -->
      <img
        v-if="resource.previewUrl && isImage(resource.file) || resource.previewUrl && resource.type == 'image'"
        :src="resource.previewUrl"
        alt="Resource Preview"
        class="w-full h-full object-cover"
      />
      <div v-else class="flex items-center justify-center h-full w-full text-gray-700">
        {{ resource.file?.name || resource.name || '' }}
      </div>

      <!-- Remove Resource Button -->
      <button
        @click="removeResource(index)"
        class="absolute top-1 right-1 bg-red-500 text-white rounded-full p-1 hover:bg-red-600"
      >
        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
        </svg>
      </button>
      
    </div>

    <!-- Upload Button -->
    <label
      for="resource-upload"
      class="w-32 h-32 flex items-center justify-center border-2 border-dashed border-gray-300 rounded-lg cursor-pointer hover:bg-gray-100"
    >
      <svg xmlns="http://www.w3.org/2000/svg" class="w-10 h-10 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v16m8-8H4" />
      </svg>
    </label>
    <input
      id="resource-upload"
      type="file"
      class="hidden"
      multiple
      @change="handleResourceUpload"
    />
  </div>
</div>



      </div>
    </div>
  </div>
</template>


<script>
import axios from 'axios';

export default {
  props: {
    courseData: {
      type: Object,
      required: false, // Make it not required to handle the undefined case
      default: () => ({}), // Provide a default value to avoid undefined errors
    },
  },
  data() {
  return {
    courseTitle: this.courseData.title || '',
    courseDescription: this.courseData.description || '',
    courseCode: this.courseData.course_code || '',
    category: this.courseData.category || '',
    categories: [],
    selectedCategories: [],
    filteredCategories: [],
    searchCategory: '',
    showCategoryDropdown: false,
    showCreateCategory: false,
    newCategoryName: '',
    newCategoryParent: '',
    searchTerm: '',
    showDropdown: false,
    price: this.courseData.price || null,
    hideFromCatalog: this.courseData.hide_from_catalog || false,
    capacity: this.courseData.capacity || null,
    level: this.courseData.level || '',
    startDate: this.courseData.start_date || '',
    startTime: this.courseData.start_time || '',
    endDate: this.courseData.end_date || '',
    endTime: this.courseData.end_time || '',
    completionRules: this.courseData.completion_rules || 'all-units',
    completionPercentage: this.courseData.completion_percentage || null, // Add this data property
    certificateType: this.courseData.certificate_type || 'Default',
    thumbnailFile: this.courseData.thumbnail_file || null,
    learningPathSearchTerm: '', // Unique search term for the learning path
    showLearningPathDropdown: false, // Dropdown state for learning path
    learningPath: [], // Holds the selected courses
    learningPathTitles: '', // Holds concatenated titles for display
    allCourses: [], // Holds all courses
    filteredLearningPathCourses: [], // Holds filtered courses
    thumbnailUrl: this.courseData.thumbnail_url || '/img/default-course-image.jpg',
    uploadedResources: [], // Holds uploaded resources as objects { file, previewUrl }
    uploadInProgress: false,  // Track the overall upload progress state

  };
},
watch: {
  allCourses: {
    handler() {
      this.initializeLearningPath();
    },
    immediate: true,
  },
},

mounted() {
  this.fetchCategories().then(() => {
    if (this.courseData.categories && this.courseData.categories.length > 0) {
      this.selectedCategories = this.courseData.categories.map(cat => ({
        id: cat.id,
        name: cat.name,
        parent_id: cat.parent_id || null,
      }));
    }
    
    // Immediately remove selected categories from the dropdown
    this.filterCategories();
  });

  this.fetchCourses();
  this.initializeLearningPath(); // Initialize learning path after courses are fetched

  if (this.courseData?.resources) {
    this.uploadedResources = this.courseData.resources.map(resource => ({
      ...resource,
      previewUrl: resource.previewUrl || (this.isImage(resource.file) ? URL.createObjectURL(resource.file) : null),
    }));
  }

  document.addEventListener('click', this.handleClickOutside);

},
  beforeUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  },
  computed: {
    thumbnailPreview() {
      if (this.thumbnailFile) {
        return URL.createObjectURL(this.thumbnailFile);
      }
      return this.thumbnailUrl;
    },
  },
  methods: {
    // Handle file input change event
    handleResourceUpload(event) {
  const files = event.target.files;


  if (files && files.length) {
    for (const file of files) {
      const existingResource = this.uploadedResources.find(
        (res) => res.name === file.name
      );

      if (!existingResource) {
        // Add new resource only if it doesn't already exist
        const temporaryUrl = this.isImage(file) ? URL.createObjectURL(file) : null;

        this.uploadedResources.push({
          file,
          name: file.name,
          previewUrl: temporaryUrl,
          uploadInProgress: false,
          file_path: null,
          type: file.type
        });
      } 
    }

    this.emitCourseData(); // Emit updated data to the parent
  }
},


    handleVideoUpload(event) {
  const file = event.target.files[0];
  if (file) {
    const temporaryUrl = URL.createObjectURL(file); // Temporary URL for preview
    this.videoFile = file; // Save the actual file object
    this.uploadInProgress = true

    // Force Vue to re-render the video element by clearing and setting uploadedVideo
    this.uploadedVideo = ''; 
    this.$nextTick(() => {
      this.uploadedVideo = temporaryUrl;
      this.emitVideoData(); // Emit data to parent
    });
  }
},

    // Remove a resource from the upload list
    removeResource(index) {
      this.uploadedResources.splice(index, 1);
      this.emitCourseData();
    },

    // Check if a file is an image
    isImage(file) {
    // Ensure `file` is defined and has a type
    return file?.type?.startsWith('image');
  },
    initializeLearningPath() {
  if (this.courseData.learning_path && this.courseData.learning_path.length > 0) {
    // Map learning_path IDs to course objects
    this.learningPath = this.courseData.learning_path
      .map((id) => this.allCourses.find((course) => course.id === id))
      .filter((course) => course); // Remove invalid mappings
  }
},
sendDataToParent() {
      // Prepare the resources to send to the parent
      const resources = this.uploadedResources.map((res) => ({
        file: res.file,   // The file itself
        name: res.name,   // The file name
        previewUrl: res.previewUrl,  // The preview URL (only for images)
        file_path: res.file_path, // The URL where the file will be uploaded to (after upload)
        uploadInProgress: res.uploadInProgress,  // Track upload progress
      }));

      this.$emit('update-unit-data', {
        title: this.courseTitle,
        description: this.courseDescription,
        resources: resources, // Emitting the resources array
        thumbnail_file: this.thumbnailFile,
        thumbnail_url: this.thumbnailUrl,
      });
    },
    async fetchCourses() {
    const query = `
      query {
        courses {
          id
          title
          category {
            name
          }
        }
      }
    `;

    try {
      const response = await axios.post(`${process.env.VUE_APP_API_URL}/graphql`, {
        query,
      });

      // Assuming response.data.data.courses holds the courses
      this.allCourses = response.data.data.courses;
      this.filterLearningPathCourses(); // Filter courses right after fetching
    } catch (error) {
      console.error('Error fetching courses with GraphQL:', error);
    }
  },
handleClickOutside(event) {
  // Reference to Learning Path Dropdown & Input
  const learningPathDropdown = this.$refs.learningPathDropdown;
  const learningPathInput = this.$refs.learningPathInput;

  // Reference to Category Dropdown & Input
  const categoryDropdown = this.$refs.categoryDropdown;
  const categoryInput = this.$refs.categoryInput;

  // Close Learning Path Dropdown if clicked outside
  if (
    learningPathDropdown && !learningPathDropdown.contains(event.target) &&
    learningPathInput && !learningPathInput.contains(event.target)
  ) {
    this.showLearningPathDropdown = false;
  }

  // Close Category Dropdown if clicked outside
  if (
    categoryDropdown && !categoryDropdown.contains(event.target) &&
    categoryInput && !categoryInput.contains(event.target)
  ) {
    this.showCategoryDropdown = false;
  }
},

    toggleLearningPathDropdown() {
      this.showLearningPathDropdown = !this.showLearningPathDropdown;
      this.filterLearningPathCourses(); // Make sure to filter when toggling
    },
    filterLearningPathCourses() {
    // Filter courses based on the search term and exclude already selected courses
    this.filteredLearningPathCourses = this.allCourses
      .filter((course) => 
        course.title.toLowerCase().includes(this.learningPathSearchTerm.toLowerCase())
      )
      .filter((course) => !this.learningPath.some(selectedCourse => selectedCourse.id === course.id)); // Exclude already selected courses
  },
    removeCourse(course) {
    this.learningPath = this.learningPath.filter(c => c.id !== course.id);
    this.learningPathTitles = this.learningPath.map((course) => course.title).join(', ');
    this.showLearningPathDropdown = false; // Close dropdown if clicked outside
    this.emitCourseData();
  },
  selectCourse(course) {
    // Add the selected course to the learning path
    if (!this.learningPath.find(selectedCourse => selectedCourse.id === course.id)) {
      this.learningPath.push(course);
    }
    
    this.learningPathTitles = this.learningPath.map((course) => course.title).join(', ');
    this.emitCourseData();
    this.filterLearningPathCourses(); // Immediately filter after selection to remove selected course
    this.showLearningPathDropdown = false;
  },
    clearLearningPath() {
      this.learningPath = [];
      this.learningPathTitles = '';
      this.emitCourseData();
    },
    async fetchCategories() {
      try {
        const response = await axios.post(`${process.env.VUE_APP_API_URL}/graphql`, {
          query: `
            query {
              categories {
                id
                name
                parent {
                  id
                  name
                }
              }
            }
          `,
        });
        this.categories = response.data.data.categories || [];
        this.filterCategories();
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    },
    filterCategories() {
  if (this.searchCategory) {
    this.filteredCategories = this.categories.filter((cat) =>
      cat.name.toLowerCase().includes(this.searchCategory.toLowerCase())
    );
  } else {
    this.filteredCategories = [...this.categories]; // Ensure it's a new array
  }

  // Remove any category that is already selected
  this.filteredCategories = this.filteredCategories.filter(
    (cat) => !this.selectedCategories.some(selected => selected.id === cat.id)
  );
},

    async addNewCategory() {
  try {
    const response = await axios.post(`${process.env.VUE_APP_API_URL}/graphql`, {
      query: `
        mutation CreateCategory($name: String!, $parent_id: Int) {
          CreateCategory(name: $name, parent_id: $parent_id) {
            id
            name
            parent {
              id
              name
            }
          }
        }
      `,
      variables: {
        name: this.newCategoryName,
        parent_id: this.newCategoryParent ? parseInt(this.newCategoryParent) : null,
      },
    });

    const newCat = response.data.data.CreateCategory;

    // Update category list and select the newly created category
    this.categories.push(newCat);
    this.selectCategory(newCat);
    this.closeCategoryModal();
  } catch (error) {
    console.error("Error creating category:", error);
  }
},
closeCategoryModal() {
      this.showCreateCategory = false;
      this.searchCategory = ''; // Reset search term when closing modal
    },
    selectCategory(category) {
  const selectedCat = {
    id: category.id,
    name: category.name,
    parent_id: category.parent ? category.parent.id : null,
  };

  // Ensure selectedCategories only includes unique categories
  if (!this.selectedCategories.some(cat => cat.id === selectedCat.id)) {
    this.selectedCategories.push(selectedCat);
  }

  // Remove the selected category from the dropdown
  this.filteredCategories = this.filteredCategories.filter(cat => cat.id !== selectedCat.id);

  this.showCategoryDropdown = false;
  this.searchCategory = ''; // Clear the search input
  this.emitCourseData();
},

removeCategory(category) {
  this.selectedCategories = this.selectedCategories.filter(cat => cat.id !== category.id);

  // Re-add removed category to the dropdown
  const categoryExists = this.categories.find(cat => cat.id === category.id);
  if (categoryExists) {
    this.filteredCategories.push(categoryExists);
  }

  // Sort categories alphabetically after adding back
  this.filteredCategories.sort((a, b) => a.name.localeCompare(b.name));

  this.emitCourseData();
},

    prepareNewCategory() {
      this.newCategoryName = this.searchCategory;
      this.newCategoryParent = '';
      this.showCreateCategory = true;
    },
    triggerThumbnailUpload() {
      this.$refs.thumbnailInput.click();
    },
    handleThumbnailUpload(event) {
      const file = event.target.files[0];
      if (file) {
        this.thumbnailFile = file;
        this.emitCourseData(); // Ensure the parent component receives the update
      }
    },
    emitCourseData() {
      const selectedCourseIds = this.learningPath.map((course) => course.id);

      const resources = this.uploadedResources.map((res) => {
    const existingResource = this.courseData.resources?.find(r => r.name === res.name);
    // const fileType = res.file.type.split('/')[0]; // Extract the type from file MIME type
    const fileType = res.file?.type; // Extract the type from file MIME type

    return {
      ...res,
      uploadInProgress: existingResource?.uploadInProgress || res.uploadInProgress || false,
      uploaded: existingResource?.uploaded || res.uploaded || false,
      file_path: existingResource?.file_path || res.file_path,
      type: existingResource?.type || fileType,
    };
  });

      this.$emit('data-updated', {
        title: this.courseTitle,
        description: this.courseDescription,
        course_code: this.courseCode,
        categories: this.selectedCategories.map(cat => ({
          id: cat.id,
          name: cat.name,
          parent_id: cat.parent ? cat.parent.id : null
        })),
        price: this.price,
        hide_from_catalog: this.hideFromCatalog,
        capacity: this.capacity,
        level: this.level,
        start_date: this.startDate,
        start_time: this.startTime,
        end_date: this.endDate,
        end_time: this.endTime,
        completion_rules: this.completionRules,
        completion_percentage: this.completionPercentage, // Include the percentage data
        learning_path: selectedCourseIds,
        certificate_type: this.certificateType,
        thumbnail_file: this.thumbnailFile,
        thumbnail_url: this.thumbnailUrl, // Preserve the existing URL if no new file
        resources, // Send the resources data
      });
    },
  },
};
</script>

<style>
.learning-path-input {
  display: flex;
  flex-wrap: wrap;
  padding: 8px;
  border: 1px solid #d1d5db;
  border-radius: 6px;
  min-height: 48px;
}

.learning-path-tag {
  display: inline-flex;
  align-items: center;
  background-color: #e0f2fe;
  color: #0369a1;
  padding: 4px 8px;
  border-radius: 4px;
  margin-right: 4px;
  margin-bottom: 4px;
}
</style>