<template>
    <BasicHeader :branch="branch" />
    <section class="bg-gray-50 relative z-0">
        <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
            <h1
                class="mb-7 text-3xl font-normal leading-none tracking-tight text-gray-900 md:text-4xl lg:text-5xl dark:text-white">
                Set Up Your Password
            </h1>

            <div
                class="w-full bg-white rounded-md shadow-xl dark:border md:mt-0 sm:max-w-xl xl:p-0 dark:bg-gray-800 dark:border-gray-700">
                <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
                    <form @submit.prevent="setPassword" class="space-y-4 md:space-y-6">
                        <div>
                            <label for="password" class="block mb-2 text-lg font-medium text-gray-900 dark:text-white">
                                New password
                            </label>
                            <input v-model="password" type="password" id="password" name="password"
                                class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-sm focus:ring-primary-600 focus:border-primary-600 block w-full px-10 py-4 placeholder-gray-400"
                                placeholder="Enter new password" required />
                        </div>
                        <div>
                            <label for="confirmPassword"
                                class="block mb-2 text-lg font-medium text-gray-900 dark:text-white">
                                Confirm new password
                            </label>
                            <input v-model="confirmPassword" type="password" id="confirmPassword"
                                name="confirmPassword"
                                class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-sm focus:ring-primary-600 focus:border-primary-600 block w-full px-10 py-4 placeholder-gray-400"
                                placeholder="Confirm new password" required />
                        </div>
                        <button type="submit" :disabled="loading"
                            class="w-full min-h-[2.5rem] text-white !bg-primary-700 hover:!bg-blue-900 focus:ring-4 focus:outline-none focus:ring-primary-300 font-semibold rounded-md text-sm px-5 py-2.5 text-center flex items-center justify-center relative">
                            <span v-if="loading" class="absolute flex justify-center items-center uppercase">
                                <svg class="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg"
                                    fill="none" viewBox="0 0 24 24">
                                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                        stroke-width="4"></circle>
                                    <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
                                </svg>
                            </span>
                            <span v-else>
                                SET PASSWORD
                            </span>
                        </button>
                        <p v-if="error" class="text-sm font-bold text-red-500">{{ error }}</p>
                        <p v-if="success" class="text-sm font-bold text-green-500">{{ success }}</p>
                    </form>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import axios from 'axios';
import BasicHeader from './headers/BasicHeader.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
    data() {
        return {
            password: '',
            confirmPassword: '',
            error: null,
            success: null,
            loading: false,
        };
    },
    components: {
        BasicHeader,
    },
    computed: {
        ...mapGetters(['getBranch']),
        branch() {
            return this.getBranch;
        },
    },
    methods: {
        ...mapActions(['fetchBranch']),
        async loadBranchData() {
            const host = window.location.host;
            const parts = host.split('.');
            const subdomain =
                (parts.length > 2 && host !== 'localhost') || (parts.length > 2 && host !== 'jamlms') ? parts[0] : null;
            if (subdomain) {
                await this.fetchBranch(subdomain);
            }
        },
        async setPassword() {
    this.loading = true;
    this.error = null;
    this.success = null;

    if (this.password !== this.confirmPassword) {
        this.error = 'Passwords do not match.';
        this.loading = false;
        return;
    }

    // Extract email and token from URL
    const urlParams = new URLSearchParams(window.location.search);
    let email = urlParams.get("email");
    const token = urlParams.get("token");

    if (!email || !token) {
        this.error = 'Invalid setup token or email.';
        this.loading = false;
        return;
    }

    // Decode email and fix + character issue
    email = decodeURIComponent(email).replace(/\s/g, '+'); // Convert spaces back to +

    try {
        const response = await axios.post(`${process.env.VUE_APP_API_URL}/graphql`, {
            query: `
            mutation ResetPassword($email: String!, $token: String!, $password: String!) {
              resetPassword(email: $email, token: $token, password: $password) {
                message
              }
            }
            `,
            variables: {
                email: email,
                token: token,
                password: this.password,
            },
        });

        if (response.data.errors) {
            const error = response.data.errors[0];

            if (error.extensions && error.extensions.validation) {
                const validationErrors = error.extensions.validation;
                this.error = Object.entries(validationErrors)
                    .map(([field, messages]) => `${field}: ${messages.join(', ')}`)
                    .join(' | ');
            } else {
                this.error = error.message || 'An error occurred while setting your password.';
            }

            return;
        }

        this.$router.push({
            name: "Login",
            query: { success: 'Your password has been set successfully. Please log in.' },
        });
    } catch (error) {
        console.error('Set password error:', error);
        this.error = 'An error occurred. Please try again later.';
    } finally {
        this.loading = false;
    }
}


    },
    created() {
        this.loadBranchData();
    }
};
</script>
