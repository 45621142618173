<template>
  <div class="antialiased bg-gray-50 dark:bg-gray-900 min-h-screen">
    <TopNav @toggle-sidebar="toggleSidebar"  />

    <!-- Sidebar -->
    <SideMenu :isOpen="isSidebarOpen" @close-sidebar="isSidebarOpen = false" />

    <main class="p-4 md:ml-64 h-auto pt-20">
      <section class="bg-gray-50 dark:bg-gray-900 p-3 sm:p-5">
        <div class="mx-auto max-w-screen-xl px-4 lg:px-12">
          <!-- Stats Section -->

          <h1 class="text-4xl font-semibold text-primary-700 mb-6">Dashboard</h1>

          <!-- Recent Course Activity -->
          <section class="mb-10 bg-white dark:bg-gray-800 rounded-lg shadow p-6">
            <CardHeader title="Recent course enrollments" />
            <div v-if="loading" class="text-center py-10">
              <svg aria-hidden="true"
                class="w-14 h-14 mx-auto text-gray-200 animate-spin dark:text-gray-600 fill-secondary-600"
                viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor" />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill" />
              </svg>
            </div>
            <div v-else-if="!courses.length" class="text-center py-10">
              <p>No recent course enrollments available.</p>
            </div>
            <div v-else class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
              <CourseTile v-for="course in courses" :key="course.id" :course="course" />
            </div>
          </section>

          <!-- Recent Course Catalog -->
          <section class="mb-10 bg-white dark:bg-gray-800 rounded-lg shadow p-6">
            <CardHeader title="Available courses" />
            <div v-if="loading" class="text-center py-10">
              <svg aria-hidden="true"
                class="w-14 h-14 mx-auto text-gray-200 animate-spin dark:text-gray-600 fill-secondary-600"
                viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor" />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill" />
              </svg>
            </div>
            <div v-else-if="!unenrolledCourses.length" class="text-center py-10">
              <p>No available courses to display.</p>
            </div>
            <div v-else class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
              <CourseTile v-for="unenrolledCourses in unenrolledCourses" :key="unenrolledCourses.id"
                :course="unenrolledCourses" :type="'catalog'" />
            </div>
          </section>

          <div class="grid grid-cols-1 sm:grid-cols-3 gap-6 mb-10">
            <div
              class="flex items-center p-6 bg-secondary-600 text-white rounded-lg shadow hover:shadow-lg transition duration-300">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2"
                stroke="currentColor" class="w-12 h-12 mr-4">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 19V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v13H7a2 2 0 0 0-2 2Zm0 0a2 2 0 0 0 2 2h12M9 3v14m7 0v4"/>
              </svg>
              <div>
                <h3 class="text-lg font-semibold">Enrolled Courses</h3>
                <p class="text-xl font-bold">{{ stats.totalCourses }}</p>
              </div>
            </div>
            <div
              class="flex items-center p-6 bg-green-600 text-white rounded-lg shadow hover:shadow-lg transition duration-300">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2"
                stroke="currentColor" class="w-12 h-12 mr-4">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m8.032 12 1.984 1.984 4.96-4.96m4.55 5.272.893-.893a1.984 1.984 0 0 0 0-2.806l-.893-.893a1.984 1.984 0 0 1-.581-1.403V7.04a1.984 1.984 0 0 0-1.984-1.984h-1.262a1.983 1.983 0 0 1-1.403-.581l-.893-.893a1.984 1.984 0 0 0-2.806 0l-.893.893a1.984 1.984 0 0 1-1.403.581H7.04A1.984 1.984 0 0 0 5.055 7.04v1.262c0 .527-.209 1.031-.581 1.403l-.893.893a1.984 1.984 0 0 0 0 2.806l.893.893c.372.372.581.876.581 1.403v1.262a1.984 1.984 0 0 0 1.984 1.984h1.262c.527 0 1.031.209 1.403.581l.893.893a1.984 1.984 0 0 0 2.806 0l.893-.893a1.985 1.985 0 0 1 1.403-.581h1.262a1.984 1.984 0 0 0 1.984-1.984V15.7c0-.527.209-1.031.581-1.403Z"/>
                </svg>
              <div>
                <h3 class="text-lg font-semibold">Courses Completed</h3>
                <p class="text-xl font-bold">{{ stats.completedCourses }}</p>
              </div>
            </div>
            <div
              class="flex items-center p-6 bg-primary-700  text-white rounded-lg shadow hover:shadow-lg transition duration-300">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2"
                stroke="currentColor" class="w-12 h-12 mr-4">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8.891 15.107 15.11 8.89m-5.183-.52h.01m3.089 7.254h.01M14.08 3.902a2.849 2.849 0 0 0 2.176.902 2.845 2.845 0 0 1 2.94 2.94 2.849 2.849 0 0 0 .901 2.176 2.847 2.847 0 0 1 0 4.16 2.848 2.848 0 0 0-.901 2.175 2.843 2.843 0 0 1-2.94 2.94 2.848 2.848 0 0 0-2.176.902 2.847 2.847 0 0 1-4.16 0 2.85 2.85 0 0 0-2.176-.902 2.845 2.845 0 0 1-2.94-2.94 2.848 2.848 0 0 0-.901-2.176 2.848 2.848 0 0 1 0-4.16 2.849 2.849 0 0 0 .901-2.176 2.845 2.845 0 0 1 2.941-2.94 2.849 2.849 0 0 0 2.176-.901 2.847 2.847 0 0 1 4.159 0Z"/>
              </svg>
              <div>
                <h3 class="text-lg font-semibold">Completion Rate</h3>
                <p class="text-xl font-bold">{{ stats.completionRate }}%</p>
              </div>
            </div>
          </div>


          <!-- My Courses Pie Chart -->
          <section class="grid grid-cols-1 md:grid-cols-2 gap-6 mb-10">
            <div class="p-6 bg-white dark:bg-gray-800 rounded-lg shadow">
              <CardHeader title="My Courses Status" />
              <div class="p-4 flex justify-center">
                <template v-if="summary.totalCourses > 0">
                  <canvas id="statusPieChart" class="max-h-96"></canvas>
                </template>
                <template v-else>
                  <p class="text-gray-500 dark:text-gray-300">No course data available for the chart.</p>
                </template>
              </div>
            </div>

            <!-- Quick Links -->
            <div class="p-6 bg-white rounded-lg shadow">
              <CardHeader title="Quick Links" />
              <div v-if="quickLinks.length > 0" class="grid grid-cols-2 gap-0 p-1 text-center mt-4" style="height: calc(100% - 80px);">
                <div
                  v-for="link in quickLinks"
                  :key="link.title"
                  @click="link.action"
                  :class="link.bgClass"
                  class="m-1 flex flex-col items-center justify-center col-span-1 transition duration-300 ease-in-out rounded-md p-4 cursor-pointer"
                >
                  <svg :class="link.iconClass" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-9 h-9 mb-2">
                    <path v-bind="link.svgPath"></path>
                  </svg>
                  <span :class="link.textClass" class="text-lg font-semibold">
                    {{ link.title }}
                  </span>
                </div>
              </div>
              <div v-else class="text-center text-gray-500 dark:text-gray-300 p-4">
                No quick links available.
              </div>
            </div>
            


          </section>

        </div>
      </section>
    </main>
  </div>
</template>

<script>
import axios from 'axios';
import TopNav from '@/components/headers/TopNav.vue';
import SideMenu from '@/components/LearnerSideMenu.vue';
import CardHeader from '@/partials/CardHeader.vue';
import CourseTile from '@/components/learner/dashboard/CourseTile.vue';
import { Chart } from 'chart.js/auto';
import { nextTick } from 'vue';

export default {
  components: { TopNav, SideMenu, CardHeader, CourseTile },
  data() {
    return {
      isSidebarOpen: false,
      stats: {
        totalCourses: 0,
        completedCourses: 0,
        completionRate: 0,
      },
      courses: [],
      summary: {
        totalCourses: 0,
        completedCourses: 0,
        completionRate: 0,
      },
      loading: true,
      courseStatus: [],
      unenrolledCourses: [],
      quickLinks: [
  {
    title: 'My Courses',
    bgClass: 'bg-purple-100 hover:bg-purple-200 focus:bg-purple-200 focus:ring-2 focus:ring-purple-500 focus:ring-opacity-50',
    iconClass: 'text-purple-500 dark:text-purple-300',
    textClass: 'text-purple-500 dark:text-purple-300',
    svgPath: {
      d: 'M5 19V4c0-.6.4-1 1-1h12c.6 0 1 .4 1 1v13H7a2 2 0 0 0-2 2Zm0 0c0 1.1.9 2 2 2h12M9 3v14m7 0v4',
    },
    action: () => this.$router.push({ name: 'My Courses' }),
  },
  {
    title: 'Course Catalog',
    bgClass: 'bg-pink-100 hover:bg-pink-200 focus:bg-pink-200 focus:ring-2 focus:ring-pink-500 focus:ring-opacity-50',
    iconClass: 'text-pink-500 dark:text-pink-300',
    textClass: 'text-pink-500 dark:text-pink-300',
    svgPath: {
      d: 'M12 11H4m15.5 5a.5.5 0 0 0 .5-.5V8a1 1 0 0 0-1-1h-3.75a1 1 0 0 1-.829-.44l-1.436-2.12a1 1 0 0 0-.828-.44H8a1 1 0 0 0-1 1M4 9v10a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1v-7a1 1 0 0 0-1-1h-3.75a1 1 0 0 1-.829-.44L9.985 8.44A1 1 0 0 0 9.157 8H5a1 1 0 0 0-1 1Z',
    },
    action: () => this.$router.push({ name: 'Course Catalog' }),
  },
  {
    title: 'My Progress',
    bgClass: 'bg-orange-100 hover:bg-orange-200 focus:bg-orange-200 focus:ring-2 focus:ring-orange-500 focus:ring-opacity-50',
    iconClass: 'text-orange-500 dark:text-orange-300',
    textClass: 'text-orange-500 dark:text-orange-300',
    svgPath: {
      d: 'M3 15v4m6-6v6m6-4v4m6-6v6M3 11l6-5 6 5 5.5-5.5',
    },
    action: () => this.$router.push({ name: 'My Progress' }),
  },
  {
    title: 'My Certificates',
    bgClass: 'bg-green-100 hover:bg-green-200 focus:bg-green-200 focus:ring-2 focus:ring-green-500 focus:ring-opacity-50',
    iconClass: 'text-green-500 dark:text-green-300',
    textClass: 'text-green-500 dark:text-green-300',
    svgPath: {
      d: 'M11 9a1 1 0 1 1 2 0 1 1 0 0 1-2 0ZM9.896 3.051a2.681 2.681 0 0 1 4.208 0c.147.186.38.282.615.255a2.681 2.681 0 0 1 2.976 2.975.681.681 0 0 0 .254.615 2.681 2.681 0 0 1 0 4.208.682.682 0 0 0-.254.615 2.681 2.681 0 0 1-2.976 2.976.681.681 0 0 0-.615.254 2.682 2.682 0 0 1-4.208 0 .681.681 0 0 0-.614-.255 2.681 2.681 0 0 1-2.976-2.975.681.681 0 0 0-.255-.615 2.681 2.681 0 0 1 0-4.208.681.681 0 0 0 .255-.615 2.681 2.681 0 0 1 2.976-2.975.681.681 0 0 0 .614-.255ZM12 6a3 3 0 1 0 0 6 3 3 0 0 0 0-6Z',
    },
    action: () => this.$router.push({ name: 'User Certificates' }),
  },
],

    };
  },
  methods: {
    toggleSidebar() {
            this.isSidebarOpen = !this.isSidebarOpen;
        },
    async fetchProgressData() {
      this.loading = true;
      const branchUrl = this.$store.state.branch ? this.$store.state.branch.url : null;

      try {
        const response = await axios.post(`${process.env.VUE_APP_API_URL}/graphql`, {
          query: `
            query fetchUserProgress($branchUrl: String) {
            UserProgress(branchUrl: $branchUrl) {
                totalCourses
                completedCourses
                averageCompletionRate
                notStartedCourses
                inProgressCourses
                courses(limit: 3) {
                  id
                  title
                  thumbnail
                  completionPercentage
                  unitsCompleted
                  totalUnits
                  timeSpent
                  enrolledDate
                }
                unenrolledCourses(limit: 3) {
                  id
                  title
                  thumbnail
                  completionPercentage
                  unitsCompleted
                  totalUnits
                  timeSpent
                  enrolledDate
                }
              }
            }
          `,
          variables: {
            branchUrl,
          },
        });
        const data = response.data.data.UserProgress;

        this.stats.totalCourses = data.totalCourses || 0;
        this.stats.completedCourses = data.completedCourses || 0;
        this.stats.completionRate = Math.round(data.averageCompletionRate) || 0;
        this.courses = data.courses || [];
        this.unenrolledCourses = data.unenrolledCourses || [];
        this.summary = {
          totalCourses: data.totalCourses,
          completedCourses: data.completedCourses,
          completionRate: Math.round(data.averageCompletionRate),
          notStartedCourses: data.notStartedCourses,
          inProgressCourses: data.inProgressCourses
        };

      } catch (error) {
        console.error('Error fetching progress data:', error);
      } finally {
        this.loading = false;
        nextTick(() => {
          this.initializeCharts();
        });
      }
    },
    initializeCharts() {
      const progressCanvas = document.getElementById('progressBarChart');
      const statusCanvas = document.getElementById('statusPieChart');

      if (progressCanvas) {
        const progressChart = progressCanvas.getContext('2d');

        // Limit to the latest 5 courses for the progress chart
        const latestCourses = this.courses.slice(0, 5);

        // Course Progress Chart - Vertical Bar
        new Chart(progressChart, {
          type: 'bar',
          data: {
            labels: latestCourses.map(course => course.title),
            datasets: [
              {
                label: 'Completion (%)',
                data: latestCourses.map(course => course.completionPercentage),
                backgroundColor: '#4CAF50',
              },
            ],
          },
          options: {
            maintainAspectRatio: false,
            responsive: true,
            plugins: {
              legend: {
                display: false,
              },
              tooltip: {
                callbacks: {
                  label: (context) => `${context.raw}%`,
                },
              },
            },
            scales: {
              x: {
                beginAtZero: true,
                title: {
                  display: true,
                  text: 'Courses',
                  color: '#666',
                },
              },
              y: {
                beginAtZero: true,
                max: 100,
                title: {
                  display: true,
                  text: 'Completion (%)',
                  color: '#666',
                },
              },
            },
          },
        });
      }

      if (statusCanvas) {
        const statusChart = statusCanvas.getContext('2d');

        new Chart(statusChart, {
          type: 'pie',
          data: {
            labels: ['Completed', 'In Progress', 'Not Started'],
            datasets: [
              {
                data: [
                  this.summary.completedCourses,
                  this.summary.inProgressCourses,
                  this.summary.notStartedCourses,
                ],
                backgroundColor: ['#4CAF50', '#FFC107', '#F44336'],
              },
            ],
          },
          options: {
            maintainAspectRatio: false,
            responsive: true,
            plugins: {
              tooltip: {
                callbacks: {
                  label: (context) => `${context.label}: ${context.raw}`,
                },
              },
            },
          },
        });
      }
    },
    formatTime(seconds) {
      const hrs = Math.floor(seconds / 3600);
      const mins = Math.floor((seconds % 3600) / 60);
      return `${hrs}h ${mins}m`;
    },
  },
  created() {
    this.fetchProgressData();
  },
};
</script>
