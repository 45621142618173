<template>
  <div class="antialiased bg-white dark:bg-gray-900 min-h-screen">
    <TopNav @toggle-sidebar="toggleSidebar"  />
    <SideMenu :isOpen="isSidebarOpen" @close-sidebar="isSidebarOpen = false" />

    <main class="pt-20 h-auto bg-gray-100 shadow-md flex justify-center">
      <div class="max-w-screen-2xl w-full md:ml-64 px-6">
        <div class="mb-4 flex justify-between items-center">
          <a href="/users" class="mt-4 ml-4 flex items-center text-primary-700 font-bold focus:outline-none">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
            </svg>
            <span class="ml-2">Back</span>
          </a>
        </div>

        <header class="text-gray-800 py-6 px-10 flex justify-between items-center rounded-lg">
          <div>
            <h2 class="text-md font-semibold mb-3">User</h2>
            <h1 class="text-4xl font-semibold">{{ `${user.first_name} ${user.last_name}` }}</h1>
          </div>
          <router-link :to="{ name: 'EditUser', params: { id: user.id } }"
          class="bg-gray-700 text-white font-semibold hover:bg-gray-800 py-2 px-4 rounded inline-flex items-center mt-4 md:mt-0">
          <svg class="w-5 h-5 mr-2 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24"
            height="24" fill="none" viewBox="0 0 24 24">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="M21 13v-2a1 1 0 0 0-1-1h-.757l-.707-1.707.535-.536a1 1 0 0 0 0-1.414l-1.414-1.414a1 1 0 0 0-1.414 0l-.536.535L14 4.757V4a1 1 0 0 0-1-1h-2a1 1 0 0 0-1 1v.757l-1.707.707-.536-.535a1 1 0 0 0-1.414 0L4.929 6.343a1 1 0 0 0 0 1.414l.536.536L4.757 10H4a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h.757l.707 1.707-.535.536a1 1 0 0 0 0 1.414l1.414 1.414a1 1 0 0 0 1.414 0l.536-.535 1.707.707V20a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1v-.757l1.707-.708.536.536a1 1 0 0 0 1.414 0l1.414-1.414a1 1 0 0 0 0-1.414l-.535-.536.707-1.707H20a1 1 0 0 0 1-1Z" />
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="M12 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
          </svg>
          Edit User
        </router-link>
        </header>

        <!-- Course Enrollment Modal -->
        <div v-if="showAddCourseModal" class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div class="bg-white dark:bg-gray-800 rounded-lg shadow-lg max-w-xl w-full p-6">
            <h2 class="text-2xl font-semibold text-gray-800 dark:text-gray-100 mb-6 text-center">Enroll User in Courses</h2>
            <input v-model="searchQuery" @input="filterAvailableCourses" type="text" placeholder="Search for a course..." class="w-full p-3 border rounded-lg dark:bg-gray-700 dark:text-white mb-4" />

            <!-- Loading Spinner for Available Courses -->
            <div v-if="loadingCourses" class="text-center py-10">
              <svg aria-hidden="true" class="w-10 h-10 mx-auto text-gray-200 animate-spin dark:text-gray-600 fill-red-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
              </svg>
              <span class="sr-only">Loading...</span>
            </div>

            <!-- Courses Table -->
            <div v-else class="overflow-auto max-h-64">
              <table class="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                <thead class="bg-gray-100 dark:bg-gray-700">
                  <tr>
                    <th class="px-4 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">Select</th>
                    <th class="px-4 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">Course Title</th>
                  </tr>
                </thead>
                <tbody class="bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-700">
                  <tr v-for="course in filteredCourses" :key="course.id">
                    <td class="px-4 py-4 whitespace-nowrap text-left">
                      <input type="checkbox" :value="course.id" v-model="selectedCourseIds" class="rounded text-primary-600 focus:ring-primary-500">
                    </td>
                    <td class="px-4 py-4 whitespace-nowrap text-gray-800 dark:text-gray-100">{{ course.title }}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="mt-6 flex justify-end space-x-4">
              <button @click="showAddCourseModal = false" class="px-5 py-1 bg-gray-500 text-white rounded-lg hover:bg-gray-600">Cancel</button>
              <button @click="enrollUserInCourses" class="px-5 py-1 bg-primary-700 text-white rounded-lg hover:bg-blue-700" :disabled="selectedCourseIds.length === 0">Enroll</button>
            </div>
          </div>
        </div>

        <!-- Confirm Removal Modal -->
        <div v-if="showConfirmModal" class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div class="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6 max-w-sm w-full">
            <h2 class="text-xl font-semibold text-gray-800 dark:text-gray-100 mb-4">Confirm Removal</h2>
            <p class="text-gray-600 dark:text-gray-400 mb-6">
              Are you sure you want to remove this course from this user? This action will result in the loss of all user progress and awards associated with this course.
            </p>
            <div class="flex justify-end space-x-4">
              <button @click="showConfirmModal = false" class="px-4 py-2 bg-gray-500 text-white rounded-lg">Cancel</button>
              <button @click="confirmDelete" class="px-4 py-2 bg-red-600 text-white rounded-lg">Confirm</button>
            </div>
          </div>
        </div>

        <div class="border-b border-gray-200 dark:border-gray-700 mt-4">
          <nav class="flex space-x-4 -mb-px">
            <button @click="activeTab = 'courses'" :class="tabClass('courses')" class="py-4 px-6 border-b-2 font-medium text-sm focus:outline-none">
              Courses
            </button>
            <button @click="activeTab = 'groups'" :class="tabClass('groups')" class="py-4 px-6 border-b-2 font-medium text-sm focus:outline-none">
              Groups
            </button>
            <button @click="activeTab = 'branches'" :class="tabClass('branches')" class="py-4 px-6 border-b-2 font-medium text-sm focus:outline-none">
              Branches
            </button>
          </nav>
        </div>
      </div>
    </main>

    <main class="p-6 md:ml-64 h-auto">
      <section class="max-w-screen-2xl mx-auto mt-6">
        <!-- Courses Tab Content -->
        <div v-if="activeTab === 'courses'" class="tab-content rounded-none bg-transparent">
          <div v-if="loadingEnrolledCourses" class="text-center py-10">
            <svg aria-hidden="true" class="w-14 h-14 mx-auto text-gray-200 animate-spin dark:text-gray-600 fill-red-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
              <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
            </svg>
            <span class="sr-only">Loading...</span>
          </div>

          <div v-if="!loadingEnrolledCourses && user.enrolledCourses.length === 0" class="text-center mt-6">
            <p class="text-lg text-gray-600 dark:text-gray-400">This user is not enrolled in any courses.</p>
            <button @click="showAddCourseModal = true" class="mt-4 px-5 py-2 bg-primary-700 text-white rounded-lg hover:bg-blue-700">Add Course</button>
          </div>

          <div v-else-if="!loadingEnrolledCourses" class="overflow-hidden shadow-md bg-white dark:bg-gray-800">
            <div class="flex justify-between items-center mb-4">
              <button @click="showAddCourseModal = true" class="px-4 py-1 bg-primary-700 text-white rounded-lg">Add Course</button>
            </div>
            <table class="min-w-full table-auto border-collapse bg-gradient-to-r from-gray-100 to-gray-200 dark:from-gray-800 dark:to-gray-700">
              <thead>
                <tr>
                  <th class="px-3 py-4 text-center text-sm font-semibold text-gray-800 dark:text-gray-300 w-16">Status</th>
                  <th class="px-6 py-4 text-left text-sm font-semibold text-gray-800 dark:text-gray-300">Course Title</th>
                  <th class="px-6 py-4 text-left text-sm font-semibold text-gray-800 dark:text-gray-300">Date Assigned</th>
                  <th class="px-6 py-4 text-left text-sm font-semibold text-gray-800 dark:text-gray-300">Progress</th>
                  <th class="px-6 py-4 text-center text-sm font-semibold text-gray-800 dark:text-gray-300 w-12"></th>
                </tr>
              </thead>
              <tbody class="bg-white dark:bg-gray-900">
                <tr
                  v-for="course in filteredEnrolledCourses"
                  :key="course.id"
                  class="hover:bg-gray-50 dark:hover:bg-gray-800 transition duration-150 ease-in-out border-b border-gray-200 dark:border-gray-700"
                >
                  <td class="px-3 py-4 whitespace-nowrap w-16 text-center">
                    <span :class="{'bg-gray-400': course.progress === 0, 'bg-yellow-500': course.progress > 0 && course.progress < 100, 'bg-green-500': course.progress === 100 }" class="inline-block w-4 h-4 rounded-full"></span>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-gray-900 dark:text-gray-100 font-semibold">{{ course.course_title }}</td>
                  <td class="px-6 py-4 whitespace-nowrap text-gray-600 dark:text-gray-400">{{ formatDate(course.date_assigned) }}</td>
                  <td class="px-6 py-4 whitespace-nowrap text-gray-600 dark:text-gray-400">
                    <div class="relative w-full h-4 bg-gray-200 dark:bg-gray-600 rounded-full overflow-hidden">
                      <div class="h-full bg-blue-500 dark:bg-blue-400" :style="{ width: course.progress + '%' }"></div>
                    </div>
                    <span class="text-xs text-gray-500 dark:text-gray-300 ml-2">{{ course.progress }}%</span>
                  </td>
                  <!-- Remove Button -->
                  <td class="px-3 py-4 text-center">
                    <button @click="confirmRemoveCourse(course.course_id)" class="text-red-600 hover:text-red-800 focus:outline-none" title="Remove user from course">
                      <svg class="w-6 h-6 text-gray-700 hover:text-gray-900" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 12h4M4 18v-1a3 3 0 0 1 3-3h4a3 3 0 0 1 3 3v1a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1Zm8-10a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"/>
                      </svg>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <!-- Groups Tab Content -->
        <div v-if="activeTab === 'groups'" class="tab-content rounded-none bg-transparent">
          <div v-if="loadingEnrolledGroups" class="text-center py-10">
            <svg aria-hidden="true" class="w-14 h-14 mx-auto text-gray-200 animate-spin dark:text-gray-600 fill-red-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
              <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
            </svg>
            <span class="sr-only">Loading...</span>
          </div>

          <div v-if="!loadingEnrolledGroups && user.groups.length === 0" class="text-center mt-6">
            <p class="text-lg text-gray-600 dark:text-gray-400">This user is not part of any groups.</p>
            <button @click="showAddGroupModal = true" class="mt-4 px-5 py-2 bg-primary-700 text-white rounded-lg hover:bg-blue-700">Add Group</button>
          </div>

          <div v-else-if="!loadingEnrolledGroups" class="overflow-hidden shadow-md bg-white dark:bg-gray-800">
            <div class="flex justify-between items-center mb-4">
              <button @click="showAddGroupModal = true" class="px-4 py-1 bg-primary-700 text-white rounded-lg">Add Group</button>
            </div>
            <table class="min-w-full table-auto border-collapse bg-gradient-to-r from-gray-100 to-gray-200 dark:from-gray-800 dark:to-gray-700">
              <thead>
                <tr>
                  <th class="px-6 py-4 text-left text-sm font-semibold text-gray-800 dark:text-gray-300">Group Name</th>
                  <th class="px-6 py-4 text-center text-sm font-semibold text-gray-800 dark:text-gray-300 w-12"></th>
                </tr>
              </thead>
              <tbody class="bg-white dark:bg-gray-900">
                <tr v-for="group in user.groups" :key="group.id" class="hover:bg-gray-50 dark:hover:bg-gray-800 transition duration-150 ease-in-out border-b border-gray-200 dark:border-gray-700">
                  <td class="px-6 py-4 whitespace-nowrap text-gray-900 dark:text-gray-100 font-semibold">{{ group.name }}</td>
                  <td class="px-3 py-4 text-center">
                    <button @click="confirmRemoveGroup(group.id)" class="text-red-600 hover:text-red-800 focus:outline-none" title="Remove user from group">
                      <svg class="w-6 h-6 text-gray-700 hover:text-gray-900" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 12h4M4 18v-1a3 3 0 0 1 3-3h4a3 3 0 0 1 3 3v1a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1Zm8-10a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"/>
                      </svg>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

<!-- Add Group Modal -->
<div v-if="showAddGroupModal" class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
  <div class="bg-white dark:bg-gray-800 rounded-lg shadow-lg max-w-xl w-full p-6">
    <h2 class="text-2xl font-semibold text-gray-800 dark:text-gray-100 mb-6 text-center">Add User to Groups</h2>

    <input v-model="groupSearchQuery" @input="filterAvailableGroups" type="text" placeholder="Search for a group..." class="w-full p-3 border rounded-lg dark:bg-gray-700 dark:text-white mb-4" />

    <div v-if="loadingGroups" class="text-center py-10">
      <svg aria-hidden="true" class="w-14 h-14 mx-auto text-gray-200 animate-spin dark:text-gray-600 fill-red-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
      </svg>
      <span class="sr-only">Loading...</span>
    </div>

    <div v-else class="overflow-auto max-h-64">
      <table class="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
        <thead class="bg-gray-100 dark:bg-gray-700">
          <tr>
            <th class="px-4 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">Select</th>
            <th class="px-4 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">Group Name</th>
          </tr>
        </thead>
        <tbody class="bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-700">
          <tr v-if="filteredGroups.length === 0">
            <td colspan="2" class="text-center text-gray-600 dark:text-gray-400 py-4">No groups available for selection.</td>
          </tr>
          <tr v-for="group in filteredGroups" :key="group.id">
            <td class="px-4 py-4 whitespace-nowrap text-left">
              <input type="checkbox" :value="group.id" v-model="selectedGroupIds" class="rounded text-primary-600 focus:ring-primary-500">
            </td>
            <td class="px-4 py-4 whitespace-nowrap text-gray-800 dark:text-gray-100">{{ group.name }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="mt-6 flex justify-end space-x-4">
      <button @click="showAddGroupModal = false" class="px-5 py-1 bg-gray-500 text-white rounded-lg hover:bg-gray-600">Cancel</button>
      <button @click="addUserToGroups" class="px-5 py-1 bg-primary-700 text-white rounded-lg hover:bg-blue-700" :disabled="selectedGroupIds.length === 0">Add</button>
    </div>
  </div>
</div>


        <!-- Confirm Removal Group Modal -->
        <div v-if="showConfirmGroupModal" class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div class="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6 max-w-sm w-full">
            <h2 class="text-xl font-semibold text-gray-800 dark:text-gray-100 mb-4">Confirm Removal</h2>
            <p class="text-gray-600 dark:text-gray-400 mb-6">
              Are you sure you want to remove this user from this group?
            </p>
            <div class="flex justify-end space-x-4">
              <button @click="showConfirmGroupModal = false" class="px-4 py-2 bg-gray-500 text-white rounded-lg">Cancel</button>
              <button @click="confirmDeleteGroup" class="px-4 py-2 bg-red-600 text-white rounded-lg">Confirm</button>
            </div>
          </div>
        </div>

      </section>
    </main>
  </div>
</template>

<script>
import axios from 'axios';
import TopNav from '@/components/headers/TopNav.vue';
import SideMenu from '@/components/SideMenu.vue';

export default {
  components: { TopNav, SideMenu },
  data() {
    return {
      user: {
        id: null,
        first_name: '',
        last_name: '',
        email: '',
        enrolledCourses: [],
        groups: [],
      },
      availableCourses: [],
      availableGroups: [], // New data for groups
      selectedCourseIds: [],
      selectedGroupIds: [], // New selected groups
      showAddCourseModal: false,
      showAddGroupModal: false, // New modal for adding groups
      showConfirmModal: false,
      showConfirmGroupModal: false, // New confirm removal modal for groups
      courseToRemove: null,
      groupToRemove: null, // New variable for group removal
      activeTab: 'courses',
      searchQuery: '',
      groupSearchQuery: '', // New search query for groups
      loadingEnrolledCourses: true,
      loadingEnrolledGroups: true, // New loading state for groups
      loadingCourses: true,
      loadingGroups: true, // New loading state for groups
      isSidebarOpen: false,
    };
  },
  computed: {
  filteredEnrolledCourses() {
    return this.user.enrolledCourses.filter(course =>
      course.course_title.toLowerCase().includes(this.searchQuery.toLowerCase())
    );
  },
  filteredCourses() {
    const enrolledCourseIds = new Set(this.user.enrolledCourses.map(course => course.course_id));
    return this.availableCourses
      .filter(course => !enrolledCourseIds.has(course.id))
      .filter(course => course.title.toLowerCase().includes(this.searchQuery.toLowerCase()));
  },
filteredGroups() {
  const enrolledGroupIds = new Set(this.user.groups.map(group => group.id)); // Collect IDs of user's assigned groups (numerical IDs)
  return this.availableGroups
    .filter(group => !enrolledGroupIds.has(Number(group.id))) // Convert group.id to a number for comparison
    .filter(group => group.name.toLowerCase().includes(this.groupSearchQuery.toLowerCase())); // Include search functionality
},


},
  async created() {
    await this.fetchUser();
    await this.fetchAvailableCourses();
    await this.fetchAvailableGroups();
  },
  methods: {
    toggleSidebar() {
            this.isSidebarOpen = !this.isSidebarOpen;
        },
    async fetchUser() {
  try {
    const response = await axios.post(`${process.env.VUE_APP_API_URL}/graphql`, {
      query: `
        query ($id: ID!) {
          user(id: $id) {
            id
            first_name
            last_name
            email
            enrolledCourses {
              id
              course_id
              course_title
              date_assigned
              progress
            }
            groups {
              id
              name
              role
              date_joined
            }
          }
        }
      `,
      variables: { id: parseInt(this.$route.params.id) },
    });
    this.user = response.data.data.user;

    // Refresh available groups after fetching user
    await this.fetchAvailableGroups();
  } catch (error) {
    console.error('Error fetching user:', error);
  } finally {
    this.loadingEnrolledCourses = false;
    this.loadingEnrolledGroups = false; // Set loading state for groups
  }
},

    async fetchAvailableCourses() {
      try {
        const response = await axios.post(`${process.env.VUE_APP_API_URL}/graphql`, {
          query: `
            query {
              courses {
                id
                title
              }
            }
          `
        });
        this.availableCourses = response.data.data.courses;
      } catch (error) {
        console.error('Error fetching courses:', error);
      } finally {
        this.loadingCourses = false;
      }
    },
    async fetchAvailableGroups() {
      try {
        const response = await axios.post(`${process.env.VUE_APP_API_URL}/graphql`, {
          query: `
            query {
              groups {
                id
                name
              }
            }
          `
        });
        this.availableGroups = response.data.data.groups; // Store available groups
        console.log("Available Groups:", this.availableGroups);
console.log("User Groups:", this.user.groups);
console.log("Filtered Groups:", this.filteredGroups);

      } catch (error) {
        console.error('Error fetching groups:', error);
      } finally {
        this.loadingGroups = false; // Set loading state for groups
      }
    },
    async enrollUserInCourses() {
      try {
        await axios.post(`${process.env.VUE_APP_API_URL}/graphql`, {
          query: `
            mutation ($user_id: Int!, $course_ids: [Int]!) {
              AdminCourseEnrollment(user_id: $user_id, course_ids: $course_ids) {
                id
                title
              }
            }
          `,
          variables: {
            user_id: this.user.id,
            course_ids: this.selectedCourseIds.map(id => parseInt(id)),
          },
        });

        this.showAddCourseModal = false;
        this.selectedCourseIds = [];
        this.fetchUser();
      } catch (error) {
        console.error('Error enrolling user in courses:', error);
      }
    },
    async addUserToGroups() {
      try {
        await axios.post(`${process.env.VUE_APP_API_URL}/graphql`, {
          query: `
            mutation ($user_id: Int!, $group_ids: [Int!]!) {
              addUserToGroup(user_id: $user_id, group_ids: $group_ids) {
                id
                name
              }
            }
          `,
          variables: {
            user_id: this.user.id,
            group_ids: this.selectedGroupIds.map(id => parseInt(id)), // Send an array of group IDs
          },
        });

        this.showAddGroupModal = false;
        this.selectedGroupIds = []; // Reset selected groups
        this.fetchUser(); // Refresh user data to include the new group assignments
      } catch (error) {
        console.error('Error adding user to groups:', error);
      }
    },
    confirmRemoveCourse(courseId) {
      this.courseToRemove = courseId;
      this.showConfirmModal = true;
    },
    async confirmDelete() {
      await this.removeCourse(this.courseToRemove);
      this.showConfirmModal = false;
      this.courseToRemove = null;
    },
    async removeCourse(courseId) {
      try {
        const response = await axios.post(`${process.env.VUE_APP_API_URL}/graphql`, {
          query: `
            mutation ($user_id: Int!, $course_id: Int!) {
              CourseUnenrollment(user_id: $user_id, course_id: $course_id) {
                id
                title
              }
            }
          `,
          variables: {
            user_id: this.user.id,
            course_id: courseId,
          },
        });
        
        this.user.enrolledCourses = this.user.enrolledCourses.filter(course => course.course_id !== courseId);
        console.log(`Successfully removed user from course: ${response.data.data.CourseUnenrollment.title}`);
      } catch (error) {
        console.error('Error removing course:', error);
      }
    },
    confirmRemoveGroup(groupId) {
      this.groupToRemove = groupId;
      this.showConfirmGroupModal = true; // Show confirm modal for groups
    },
    async confirmDeleteGroup() {
      await this.removeGroup(this.groupToRemove);
      this.showConfirmGroupModal = false;
      this.groupToRemove = null;
    },
    async removeGroup(groupId) {
  try {
    const response = await axios.post(`${process.env.VUE_APP_API_URL}/graphql`, {
      query: `
        mutation ($user_id: Int!, $group_id: Int!) {
          removeUserFromGroup(user_id: $user_id, group_id: $group_id) {
            id
            name
          }
        }
      `,
      variables: {
        user_id: this.user.id,
        group_id: groupId,
      },
    });

    // Update the user's groups list
    this.user.groups = this.user.groups.filter(group => group.id !== groupId);
    console.log(`Successfully removed user from group: ${response.data.data.removeUserFromGroup.name}`);

    // Refresh the user's enrolled courses to reflect any changes
    await this.fetchUser();
  } catch (error) {
    console.error('Error removing group:', error);
  }
},
    tabClass(tab) {
      return {
        'text-primary-700 border-b-2 border-primary-700 pb-2 font-semibold': this.activeTab === tab,
        'text-gray-500 pb-2 hover:text-gray-700': this.activeTab !== tab,
      };
    },
    formatDate(date) {
      if (!date) return 'N/A';
      const options = { year: 'numeric', month: 'short', day: 'numeric' };
      return new Date(date).toLocaleDateString(undefined, options);
    },
  },
};
</script>
